<template>
  <draggable
    tag="div"
    :list="props.gatherSelectedOnTop ? props.selected : props.all"
    :group="{ name: props.name }"
    handle=".handle"
    :item-key="(el: string) => el"
  >
    <template #item="{ element, index }: { element: string, index: number }">
      <div class="h-8 flex items-center cursor-pointer select-none text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900">
        <div class="handle flex justify-center cursor-grab py-2 w-6 text-gray-400 transition-colors hover:text-gray-700">
          <dynamic-icon i="ic-baseline-drag-indicator" />
        </div>
        <div class="flex flex-1 items-center" @click="toggle(props.selected, element)">
          <label class="switch pointer-events-none mr-2 w-6">
            <input type="checkbox" :checked="element in selectedKeys" />
            <span class="slider round"></span>
          </label>
          <slot name="up" :element="element">
            <div class="flex h-full flex-1 items-center gap-1">
              {{ element }}
            </div>
          </slot>
        </div>
      </div>
    </template>
  </draggable>
  <template v-if="props.gatherSelectedOnTop">
    <template v-for="(key, index) in props.all">
      <div
        class="h-8 flex items-center cursor-pointer select-none text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
        v-if="!props.selected.includes(key)"
        @click="toggle(props.selected, key)"
      >
        <div class="w-6"></div>
        <label class="switch pointer-events-none mr-2 w-6">
          <input type="checkbox" :checked="key in selectedKeys" />
          <span class="slider round"></span>
        </label>
        <slot name="down" :element="key">
          <div class="flex h-full flex-1 items-center gap-1">
            {{ key }}
          </div>
        </slot>
      </div>
    </template>
  </template>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import draggable from 'vuedraggable'

interface IProps {
  all: string[]
  selected: string[]
  name: string
  gatherSelectedOnTop: boolean
}

const props = withDefaults(defineProps<IProps>(), {
  gatherSelectedOnTop: true
})

const selectedKeys = computed(() => props.selected.reduce((acc, s) => {
  acc[s] = s
  return acc
}, {}))

function toggle(list: string[], key: string) {
  const index = list.findIndex(el => el === key)
  if (index !== -1) list.splice(index, 1)
  else list.push(key)
}
</script>
