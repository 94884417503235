<template>
  <!-- tree-grid-header-actions -->
  <div class="sticky top-0 z-[140] bg-white">
    <div class="flex">
      <slot></slot>
      <div
        v-if="state?.gridIndexLast || state?.gridIndexLast === 0"
        class="sticky right-0 flex items-center gap-1 text-base text-gray-700"
      >
        <div class="w-[12ch] text-right text-sm">
          <template v-if="state.selected.length === 0"> Total rows {{ state.gridIndexLast + 1 }} </template>
          <template v-else-if="state.selected.length === 1">
            Row {{ state.selected[0].index + 1 }} / {{ state.gridIndexLast + 1 }}
          </template>
          <template v-else-if="state.selected.length > 1">
            Selected {{ state.selected.length + 1 }} of {{ state.gridIndexLast + 1 }}
          </template>
        </div>
        <div class="w-1"></div>
        <visage-button
          :disable="state.selected.length === 0 || state.selected.at(-1)?.index === 0"
          size="sm"
          @click="selectPrevious"
        >
          <template #icon-center>
            <dynamic-icon i="ic-baseline-keyboard-arrow-up" />
          </template>
        </visage-button>
        <visage-button
          :disable="state.selected.length === 0 || state.selected.at(-1)?.index === state.gridIndexLast"
          size="sm"
          @click="selectNext"
        >
          <template #icon-center>
            <dynamic-icon i="ic-baseline-keyboard-arrow-down" />
          </template>
        </visage-button>
        <div class="w-0.5"></div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ITreeGridState as ITreeGridStateHack, treeGridKeys } from '@common'
import { inject } from 'vue'
interface ITreeGridState extends ITreeGridStateHack {}

const state = inject<ITreeGridState>(treeGridKeys.state)

function selectNext() {
  state?.selectNext()
}

function selectPrevious() {
  state?.selectPrevious()
}
</script>
