<template>
  <!-- tree-node -->
  <div class="flex w-full">
    <tree-sticky-row
      :class="props.treeStyles?.class_node"
      :style="props.treeStyles?.style_node"
      :class-root="props.treeStyles?.class_node_root"
      :is-sticky="props.isSticky"
      :left-offset-total="props.leftOffsetTotal"
      :sticky-side-width="props.treeStyles?.sticky_side_width"
      ref="stickyRowRef"
    >
      <div
        class="group flex w-fit items-center"
        :class="[props.treeStyles?.class_node_header, props.isSticky ? 'sticky' : '']"
        :style="{ left: props.leftOffsetTotal + borderLeftWidth + 'px' }"
      >
        <!--
          @slot Header of a tree node
          @binding {boolean}    is-expanded       Boolean that indicates if current node is expanded or not
          @binding {function}   toggle-expanded   Function that allows to toggle the expanded state of the tree node
          @binding {function}   toggle-parents    Function that allows to toggle the expanded state of the parent nodes
        -->
        <slot
          name="header"
          :is-expanded="isExpanded"
          :toggle-expanded="toggleExpanded"
          :toggle-parents="toggleParents"
        ></slot>
      </div>
      <div class="flex w-full" :class="props.treeStyles?.class_content_root">
        <!-- Area left to the main content, remains sticky -->
        <div
          v-if="props.leftOffset"
          class="flex w-fit shrink-0 grow-0 bg-sticky"
          :class="[props.isSticky ? 'sticky z-[130] ' : '']"
          ref="contentLeftRef"
          :style="{
            left: props.leftOffsetTotal + borderLeftWidth + 'px',
            width: props.leftOffset + 'px',
          }"
        >
          <!-- @slot Content of the sticky part at the left -->
          <slot name="content-left"></slot>
        </div>
        <div class="flex w-fit"></div>
        <!--
          @slot Content of a tree node
          @binding {boolean}    is-expanded       Boolean that indicates if current node is expanded or not
          @binding {number}     left-offset-total Total offset from the left (in px) based on the nodes's current level on the tree
          @binding {function}   toggle-expanded   Function that allows to toggle the expanded state of the tree node
          @binding {function}   toggle-parents    Function that allows to toggle the expanded state of the parent nodes
        -->
        <slot
          name="content"
          v-if="$slots['content']"
          :is-expanded="isExpanded"
          :left-offset-total="props.leftOffsetTotal + borderLeftWidth + props.leftOffset"
          :toggle-expanded="toggleExpanded"
          :toggle-parents="toggleParents"
          class="w-full"
        ></slot>
        <!-- @slot Appears when no main content has been provided -->
        <slot v-else name="content-empty"></slot>
      </div>
      <div
        v-if="$slots['footer']"
        class="group flex w-fit items-center"
        :class="[props.treeStyles?.class_node_footer, props.isSticky ? 'sticky' : '']"
        :style="{ left: props.leftOffsetTotal + borderLeftWidth + 'px' }"
      >
        <!--
          @slot Footer of a tree node
          @binding {boolean}    is-expanded       Boolean that indicates if current node is expanded or not
          @binding {function}   toggle-expanded   Function that allows to toggle the expanded state of the tree node
          @binding {function}   toggle-parents    Function that allows to toggle the expanded state of the parent nodes
        -->
        <slot
          name="footer"
          :is-expanded="isExpanded"
          :toggle-expanded="toggleExpanded"
          :toggle-parents="toggleParents"
        ></slot>
      </div>
    </tree-sticky-row>
  </div>
</template>

<script setup lang="ts">
import { ITreeStyles as ITreeStylesHack, ElementReactiveRef, elementReactiveRef } from '@common'
import { isNumeric } from '@common'
import { ref, computed } from 'vue'
interface ITreeStyles extends ITreeStylesHack {}

interface IProps {
  /**
   * Styles applied to the tree node
   */
  treeStyles?: ITreeStyles
  /**
   * Width to be applied to the content-left (in px)
   */
  leftOffset?: number
  /**
   * Total offset from the left (in px) based on the node's current level on the tree
   */
  leftOffsetTotal?: number
  /**
   * Boolean that indicates if responsive styles should be applied
   */
  isSticky?: boolean
  /**
   * Boolean that indicates if node is initially expanded or not
   * TODO: Maybe transform this to a v-model because currently this only sets the initial value
   */
  isExpanded?: boolean
}

const props = withDefaults(defineProps<IProps>(), {
  leftOffset: 0,
  leftOffsetTotal: 0,
  isSticky: false,
  isExpanded: true,
})

const emit = defineEmits<{
  /**
   * Fires when node expands
   */
  (e: 'expand'): void
  /**
   * Fires when node collapses
   */
  (e: 'collapse'): void
  /**
   * Directs parent nodes to expand, collapse or toggle their state
   * @param shouldExpand Boolean that indicates whether parent nodes should expand (true), collapse (false) or toggle (undefined)
   */
  (e: 'toggle-parents', shouldExpand?: boolean): void
}>()

const isExpanded = ref(props.isExpanded)
const stickyRowRef = ref<{ rowRef: ElementReactiveRef }>()
const contentLeftRef = ref<HTMLElement>()

const borderLeftWidth = computed(() => {
  const borderStr = props.treeStyles?.style_node?.borderLeftWidth ?? props.treeStyles?.style_node?.borderWidth ?? '0'
  const borderInt = parseInt(borderStr as string)
  return isNumeric(borderInt) ? borderInt : 0
})
const nodeRef = computed(() => stickyRowRef.value?.rowRef)

function toggleParents(shouldExpand?: boolean) {
  toggleExpanded(shouldExpand)
  emit('toggle-parents', shouldExpand)
}

function toggleExpanded(shouldExpand?: boolean) {
  if (shouldExpand !== undefined) isExpanded.value = shouldExpand
  else isExpanded.value = !isExpanded.value

  if (isExpanded.value) emit('expand')
  else emit('collapse')
}

defineExpose({
  nodeRef,
})
</script>
