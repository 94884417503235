export default {
  logo: "logo-agache.png",
  nav: "logo-agache.png",
  icon: "logo-agache.png",
  navSmall: "logo-agache.png",
  auth: {
    domain: import.meta.env.VITE_AUTH0_DOMAIN,
    clientId: import.meta.env.VITE_AUTH0_CLIENT_ID,
    project: import.meta.env.VITE_PROJECT,
    urls: ["https://fireauth.100m.io/"],
  },
  commandr: import.meta.env.VITE_COMMANDR,
  exporter: import.meta.env.VITE_EXPORTER,
  project: import.meta.env.VITE_PROJECT,
  db: import.meta.env.VITE_DB || "firebase",
  firebase: {
    apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
    name: import.meta.env.VITE_FIREBASE_NAME,
    project: import.meta.env.VITE_FIREBASE_PROJECT,
    root: import.meta.env.VITE_FIREBASE_ROOT || import.meta.env.VITE_FIREBASE_PROJECT,
  },
  graphqlEndpoint: import.meta.env.VITE_GRAPHQL_ENDPOINT || "/graphql",
  mode: import.meta.env.MODE,
}
