<template>
  <!-- visage-button -->
  <button
    class="vsg-unset flex items-center gap-1 text-center transition-all"
    :class="[
      $slots['icon-left'] ? 'py-1 pl-1 pr-2' : '',
      $slots['icon-right'] ? 'py-1 pl-2 pr-1' : '',
      $slots['icon-center'] ? [currentSize.width, 'justify-center'] : '',
      !$slots['icon-left'] && !$slots['icon-right'] && !$slots['icon-center'] ? 'py-1 pl-2 pr-2' : '',
      $attrs.disable ? 'cursor-default text-gray-400' : [colors.idle, colors.hover, colors.active],
      currentSize.height,
      currentSize.text,
      currentShape,
    ]"
  >
    <slot name="icon-left" v-if="$slots['icon-left']"></slot>
    <slot name="icon-center" v-if="$slots['icon-center']"></slot>
    <slot></slot>
    <slot name="icon-right" v-if="$slots['icon-right']"></slot>
  </button>
</template>

<script setup lang="ts">
// Inspired from https://material.angular.io/components/button/overview

import { computed } from 'vue'

type IButtonSize = 'xs' | 'sm' | 'md' | 'base' | 'lg' | 'custom'
type IButtonShape = 'rounded' | 'rectangle' | 'circle'
type IButtonType = 'basic' | 'outlined'
type IButtonColor = 'visage' | 'green' | string
type IButtonDarken = 0 | 1 | 2
type IButtonColorProperties = 'idle' | 'hover' | 'active'
type IButtonColorVarieties = {
  [x in IButtonColorProperties]: string | string[]
}
type IButtonColorTypes = {
  [x in IButtonType]: IButtonColorVarieties
}
type IButtonColors = {
  [x in IButtonColor]: IButtonColorTypes
}

interface IProps {
  /*
    basic: just text
    flat: text + bg color
    outlined: text + outline
   */
  type?: IButtonType // Future also : 'flat' ,
  /*
    rectangle: no rounded corners
    rounded: rectangular with a bit rounded corners
    circle: fully rounded corners
    @values rounded, rectangle
   */
  shape?: IButtonShape
  /*
    xs: 20px
    sm: 24px
    md: 28px
    base: 32px
    lg: 36px
    @values xs, sm, md, base, lg
   */
  size?: IButtonSize
  // If background needs to be darkened to make it stand out from surroundings
  darken?: IButtonDarken
  color?: IButtonColor
  colorDefinitions?: IButtonColors
}

const props = withDefaults(defineProps<IProps>(), {
  type: 'basic',
  shape: 'rounded',
  size: 'base',
  darken: 1,
  color: 'green',
})

const currentSize = computed(() => calculateSizes(props.size))
const currentShape = computed(() => calculateShape(props.shape))
const colors = computed(() => calculateColors(props.color, props.darken, props.type))

function calculateSizes(size: IButtonSize) {
  const [height = '', width = '', text = ''] = {
    custom: ['', ''],
    base: ['h-8', 'w-8'],
    xs: ['h-5', 'w-5'],
    sm: ['h-6', 'w-6', 'font-medium text-base'],
    md: ['h-7', 'w-7'],
    lg: ['h-9', 'w-9'],
  }[size]

  return {
    height,
    width,
    text,
  }
}

function calculateShape(shape: IButtonShape) {
  const shapes = {
    rectangle: 'rounded-0',
    circle: 'rounded-full',
    rounded: 'rounded',
  }

  return shapes[shape] ?? shapes['rounded']
}

function getColor(property: IButtonColorProperties) {
  const colorDefinitions: IButtonColors = {
    green: {
      basic: {
        idle: 'text-green-900',
        hover: ['hover:bg-emerald-100', 'hover:bg-emerald-200', 'hover:bg-emerald-300'],
        active: ['active:bg-emerald-200', 'active:bg-emerald-300', 'active:bg-emerald-400'],
      },
      outlined: {
        idle: 'text-green-900 border border-solid border-green-900',
        hover: ['hover:bg-emerald-100', 'hover:bg-emerald-200', 'hover:bg-emerald-300'],
        active: ['active:bg-emerald-200', 'active:bg-emerald-300', 'active:bg-emerald-400'],
      },
    },
    visage: {
      basic: {
        idle: 'text-gray-900',
        hover: ['hover:bg-accent hover:border-accent hover:text-white'],
        active: [''], //TODO:
      },
      outlined: {
        idle: 'text-gray-900 border border-solid border-accent duration-75',
        hover: ['hover:bg-accent hover:text-white'],
        active: [''], //TODO:
      },
    },
    ...props.colorDefinitions,
  }

  return getColorByHue(colorDefinitions[props.color][props.type][property])
}

function getColorByHue(colors: string | string[]) {
  if (Array.isArray(colors)) return colors.at(props.darken) ?? colors.at(-1) ?? ''
  return colors
}

function calculateColors(color: IButtonColor, darken: IButtonDarken, type: IButtonType): IButtonColorVarieties {
  return {
    idle: getColor('idle'),
    hover: getColor('hover'),
    active: getColor('active'),
  }
}
</script>
