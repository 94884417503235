<script setup>
import { ref, computed, watch } from "vue"
import "../index.css"

// example of data :
// [{
//   "Company": "THOR ASGARD",
//   "AUM": 85215419.11,
//   "Performance": 52.23
// }]

// example of options :
// const options = {
//   primary_columns: ["Performance"],
//   secondary_columns: ["AUM", "Company", "Performance"],
//   sort_column: "Performance",
//   table_size: 10,
//   format: {
//     AUM: ".3s€",
//     Performance: ".2%",
//   },
//   colored_text : "Performance"
// }

const props = defineProps({
  data: [Object, Array],
  options: {
    type: [Object, Array],
    default: {},
  },
})

const column_keys = props.data[0].__.keys()

const component_options = {
  ...props.options,
  // primary_columns to filter the wanted columns in the table
  primary_columns: props.options.primary_columns || column_keys,
  // secondary_columns to show a detailed table on the right
  secondary_columns: props.options.secondary_columns || null,
  // sort_column : The column to sort
  sort_column: props.options.sort_column || column_keys[0],
  table_size: props.options.table_size || 50,
  format: props.options.format || null,
  colored_text: props.options.colored_text || null,
  detailed_cell: props.options.detailed_cell || props.options.primary_columns || column_keys,
  id: props.options.id || null,
}

// parameters of sorting
const table_sort = ref(component_options.sort_column)
const update_sort = (column_name) => {
  if (table_sort.value === column_name) return (table_sort.value = "-" + column_name)
  table_sort.value = column_name
}

const format_cells = (array) => {
  return array.map((row) => row.__.map((value, key) => format(component_options.format[key])(value)))
}

// sort table
const table_data = computed(() => {
  const [match, direction = "+", col] = table_sort.value.match(/(^-)?(.*)/)
  let columns = direction + col
  const sorted = props.data.sort(columns)
  const formatted_sorted = component_options.format ? format_cells(sorted) : sorted
  return formatted_sorted
})

const row_id_panel = ref(null)

// Second table for detail information
const row_panel = computed(() => {
  const rows = table_data.value
  if (rows.length === 0) return
  const row = rows.find((row) => row[column_keys[0]] === row_id_panel.value) || rows[0]
  return row
})

// scroll size and download data
const table_size = ref(component_options.table_size)

const table_scroll = ($event) =>
  requestAnimationFrame(() => {
    if ($event.target.scrollTop < $event.target.scrollHeight - $event.target.clientHeight) return
    table_size.value += component_options.table_size
  })
watch(table_data, () => (table_size.value = component_options.table_size))
</script>

<template>
  <div>
    <div class="flex h-auto overflow-hidden rounded bg-white !shadow-none" style="max-height: 75vh">
      <div class="relative flex-grow overflow-x-auto overflow-y-auto" @scroll="table_scroll">
        <div class="inline-block min-w-full align-middle">
          <table :id="component_options.id" class="min-w-full">
            <thead class="sticky top-0 bg-white border-b-2">
              <tr>
                <th
                  v-for="column_title in component_options.primary_columns"
                  :key="column_title"
                  scope="col"
                  class="h-16 cursor-pointer px-3"
                  @click="update_sort(column_title)"
                >
                  <div class="flex justify-end items-center break-words text-right font-extrabold capitalize min-w-min">
                    {{ column_title }}
                    <span class="ml-3 w-4">
                      <svg
                        v-if="table_sort === column_title"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="iconify iconify--bi w-6"
                        preserveAspectRatio="xMidYMid meet"
                        viewBox="0 0 16 16"
                      >
                        <g fill="currentColor">
                          <path
                            d="M3.5 12.5a.5.5 0 0 1-1 0V3.707L1.354 4.854a.5.5 0 1 1-.708-.708l2-1.999l.007-.007a.498.498 0 0 1 .7.006l2 2a.5.5 0 1 1-.707.708L3.5 3.707V12.5zm3.5-9a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zM7.5 6a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zm0 3a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z"
                          ></path>
                        </g>
                      </svg>
                      <svg
                        v-if="table_sort === '-' + column_title"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        class="iconify iconify--bi w-6"
                        preserveAspectRatio="xMidYMid meet"
                        viewBox="0 0 16 16"
                      >
                        <g fill="currentColor">
                          <path
                            d="M3.5 2.5a.5.5 0 0 0-1 0v8.793l-1.146-1.147a.5.5 0 0 0-.708.708l2 1.999l.007.007a.497.497 0 0 0 .7-.006l2-2a.5.5 0 0 0-.707-.708L3.5 11.293V2.5zm3.5 1a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zM7.5 6a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zm0 3a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z"
                          ></path>
                        </g></svg
                    ></span>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(row, index) in table_data"
                :key="index"
                @mouseover="row_id_panel = row[column_keys[0]]"
              >
                <td
                  v-for="(column_group, index) in component_options.detailed_cell"
                  :key="index"
                  class="min-w-[140px] whitespace-nowrap px-4 py-2"
                  :class="
                    row[component_options.colored_text] < '0%' && column_group == component_options.colored_text
                      ? 'font-medium text-red-500'
                      : row[component_options.colored_text] > '0.0001%' && column_group == component_options.colored_text
                      ? 'font-medium text-green-500'
                      : 'font-normal text-gray-900'
                  "
                >
                  <slot name="table" :row="row" :column_group="column_group"> {{ row[column_group] }}</slot>
                </td>
              </tr>
            </tbody>
            <slot name="tfoot"></slot>
          </table>
        </div>
      </div>
      <div v-if="component_options.secondary_columns" class="panel min-w-[310px] max-w-[450px] overflow-y-auto bg-gray-100 p-4">
        <div class="inline-block min-w-full align-middle">
          <div class="overflow-hidden">
            <table class="min-w-full">
              <tr v-for="(column, index) in component_options.secondary_columns" :key="index" class="border-grey border-b-[1.5px] p-1">
                <td class="w-1 whitespace-nowrap p-1 text-right text-sm font-bold text-gray-900">{{ column }}:</td>
                <td
                  class="whitespace-normal p-1 text-sm"
                  :class="
                    row_panel?.[component_options.colored_text] < '0%' && column == component_options.colored_text
                      ? 'font-medium text-red-500'
                      : row_panel?.[component_options.colored_text] > '0.0001%' && column == component_options.colored_text
                      ? 'font-medium text-green-500'
                      : 'font-normal text-gray-900'
                  "
                >
                  {{ row_panel?.[column] }}
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
