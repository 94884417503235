<template>
  <!-- tree-grid-row -->
  <div ref="rowRef">
    <tree-sticky-row
      :is-sticky="treeState.isSticky"
      :left-offset-total="props.leftOffsetTotal"
      :sticky-side-width="props.gridStyles?.sticky_side_width"
      :class-root="['group', props.gridStyles?.class_row_root]"
      :class="[
        'flex items-stretch',
        props.gridStyles?.class_row,
        isSelected ? props.gridStyles?.class_row__selected : '',
        isHighlighted && !isSelected ? props.gridStyles?.class_row__highlighted : '',
        !props.isDragging && !isSelected ? props.gridStyles?.class_row__hover : '',
      ]"
      :style="props.gridStyles?.style_row"
      @[clickOnAllowSelect]="treeState.toggleSelected(props.rowData.gridIndex as number)"
    >
      <template #aside v-if="treeState.freezedColumns">
        <tree-grid-cells
          :max-cells="treeState.freezedColumns"
          :row-cells="rowCells"
          :grid-styles="props.gridStyles"
          :is-selected="isSelected"
          :column-sizes="(columnSizes as number[])"
          :min-column-size="treeState.minColumnSize"
          :column-size-measurement="columnSizeMeasurement"
          :is-scrolled="treeState.overflowRef.scroll.x > 0"
        ></tree-grid-cells>
      </template>
      <!--
        @slot Actions that appear on the left side of the row
        @binding {IProps}   row-props    All props available to the row
        @binding {boolean}  is-dragging  Boolean that indicates if a drag&drop is currently occuring
        @binding {boolean}  is-selected  Boolean indicating if current row is selected
      -->
      <slot name="actions-left" :row-props="props" :is-dragging="props.isDragging" :is-selected="isSelected" />
      <tree-grid-cells
        :row-cells="rowCells"
        :grid-styles="props.gridStyles"
        :is-selected="isSelected"
        :column-sizes="(columnSizes as number[])"
        :min-column-size="treeState.minColumnSize"
        :column-size-measurement="columnSizeMeasurement"
      ></tree-grid-cells>
      <div class="grow"></div>
      <!--
        @slot Actions that appear on the right side of the row
        @binding {IProps}   row-props    All props available to the row
        @binding {boolean}  is-dragging  Boolean that indicates if a drag&drop is currently occuring
        @binding {boolean}  is-selected  Boolean indicating if current row is selected
      -->
      <slot name="actions-right" :row-props="props" :is-dragging="props.isDragging" :is-selected="isSelected" />
    </tree-sticky-row>
  </div>
</template>

<script setup lang="ts">
import {
  IGridStyles as IGridStylesHack,
  isNumeric,
  ITreeGridItemState as ITreeGridItemStateHack,
  ITreeGridState as ITreeGridStateHack,
  ITreeGridTable as ITreeGridTableHack,
  ITreeGridTableCell as ITreeGridTableCellHack,
  ITreeGridTableRow as ITreeGridTableRowHack,
  treeGridKeys,
  useTreeGridItemState,
} from '@common'
import { computed, ref, watchEffect, Ref, watch, inject } from 'vue'

interface IGridStyles extends IGridStylesHack {}
interface ITreeGridItemState extends ITreeGridItemStateHack {}
interface ITreeGridState extends ITreeGridStateHack {}
interface ITreeGridTable extends ITreeGridTableHack {}
interface ITreeGridTableCell extends ITreeGridTableCellHack {}
interface ITreeGridTableRow extends ITreeGridTableRowHack {}

interface IProps {
  /**
   * Data representing current grid
   * @unused
   */
  gridData?: ITreeGridTable
  /**
   * Data representing current row
   */
  rowData: ITreeGridTableRow
  /**
   * Local index of the row inside current grid
   * For global index inside tree, use props.rowData.gridIndex
   * @unused
   */
  index?: number
  /**
   * Number of rows in current grid
   * @unused
   */
  length?: number
  /**
   * Index that will be shown for the current row, independently of what the index actually is
   */
  indexToShow?: number
  /**
   * Styles applied to the grid
   */
  gridStyles?: IGridStyles
  /**
   * Boolean that indicates if a drag&drop is currently occuring
   */
  isDragging?: boolean
  /**
   * Total offset from the left (in px) based on the nodes's current level on the tree
   */
  leftOffsetTotal: number
  /**
   * Adjust first column by the appropriate number of pixels
   */
  firstColumnAdjust?: number
}

const props = withDefaults(defineProps<IProps>(), {
  isDragging: false,
  firstColumnAdjust: 0,
})

const treeState = inject<ITreeGridState>(treeGridKeys.state) as ITreeGridState

const emit = defineEmits<{
  /**
   * Fires when row is selected
   * @param rowData Data representing selected row
   */
  (e: 'select', rowData: ITreeGridTableRow): void
  /**
   * Fires when row is unselected
   * @param rowData Data representing unselected row
   */
  (e: 'unselect', rowData: ITreeGridTableRow): void
  /**
   * Fires when row is highlighted
   * @param rowData Data representing highlighted row
   */
  (e: 'highlight', rowData: ITreeGridTableRow): void
  /**
   * Fires when row is unhighlighted
   * @param rowData Data representing unhighlighted row
   */
  (e: 'unhighlight', rowData: ITreeGridTableRow): void
}>()

const isSelected = ref(false)
const isHighlighted = ref(false)
const rowRef = ref<HTMLElement>()
const rowState = useTreeGridItemState({
  index: props.rowData.gridIndex ?? -1,
  itemId: props.rowData.row_id,
  itemRef: rowRef,
  itemData: props.rowData,
  treeState,
})

const hasIndex = computed(() => isNumeric(props.rowData.gridIndex!) && props.rowData.gridIndex !== -1)

watchEffect(() => {
  hasIndex && rowState.updateIndex(props.rowData.gridIndex ?? 0)
})

watch(
  treeState.selected,
  () => {
    updateRowStatus(treeState.getSelected, treeState.updateSelectedState, isSelected, 'select', 'unselect')
  },
  { immediate: true },
)

watch(
  treeState.highlighted,
  () => {
    updateRowStatus(
      treeState.getHighlighted,
      treeState.updateHighlightedState,
      isHighlighted,
      'highlight',
      'unhighlight',
    )
  },
  { immediate: true },
)

/**
 * Updates row to indicated status (ex. Selected)
 * @param getState Function that will be called to retrieve current status
 * @param updateState Function that will be called to update status of row
 * @param isStatus Boolean indicating the current status of the row
 * @param event Event that will be emmited if status becomes active
 * @param unevent Event that will be emmited if status becomes unactive
 */
function updateRowStatus(
  getState: (gridIndex: number) => ITreeGridItemState | undefined,
  updateState: (gridIndex: number, state: ITreeGridItemState) => void,
  isStatus: Ref<boolean>,
  event: 'select' | 'highlight',
  unevent: 'unselect' | 'unhighlight',
) {
  if (!hasIndex) return

  const isActive = !!getState(props.rowData.gridIndex ?? -1)
  if (isActive) updateState(props.rowData.gridIndex ?? -1, rowState)
  if (isActive === isStatus.value) return

  isStatus.value = isActive
  emit((isActive ? event : unevent) as any, props.rowData)

  if (event === 'select' && isActive) {
    rowState.scrollOverflow(props.rowData.gridIndex as number, treeState)
  }
}

const firstColumnSize = computed(
  () =>
    (treeState.columnSizes?.[0] ?? treeState.minColumnSize) -
    props.leftOffsetTotal -
    (props.gridStyles?.sticky_side_width ?? 0) +
    props.firstColumnAdjust,
)
const clickOnAllowSelect = computed<any>(() => (treeState.allowSelect && hasIndex ? 'click' : null))

const rowCells = computed(() => {
  return treeState.showIndex
    ? [
        {
          value: `${props.indexToShow ?? (props.rowData.gridIndex ?? 0) + 1}`,
          cell_styles: {
            // class_cell: 'text-base justify-center',
            class_cell_container: 'text-base justify-center',
          },
        } as ITreeGridTableCell,
      ].concat(props.rowData?.cells as any[])
    : props.rowData?.cells
})

const fixColumnSize = () => {
  return treeState.showIndex
    ? [treeState.minIndexColumnSize, firstColumnSize.value].concat(treeState.columnSizes.slice(1))
    : [firstColumnSize.value].concat(treeState.columnSizes.slice(1))
}
const percentageColumnSize = () => {
  return treeState.columnSizes
}

const columnSizes = computed(() => (treeState.isPercent ? percentageColumnSize() : fixColumnSize()))
const columnSizeMeasurement = computed(() => (treeState.isPercent ? '%' : 'px'))
</script>
