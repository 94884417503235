<script setup>
import { ref } from "vue"
</script>
<script>
export const additions = { icon: "pt-icon-heat-grid" }
</script>

<template>
  <h1 class="text-3xl font-bold">{{ t["prod-details"] }}</h1>
</template>
