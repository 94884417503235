<template>
  <!-- tree-sticky-row -->
  <article class="flex w-full items-stretch" :class="props.classRoot">
    <!-- Sticky part - Semantic tags make it easier to target them with css -->
    <aside
      v-if="props.isSticky"
      :class="[props.isSticky ? 'sticky z-[120]' : 'fixed']"
      :style="{ left: props.leftOffsetTotal + 'px' }"
    >
      <!-- sticky-side: the part that remains sticky -->
      <!-- we should only fix border, padding, margin & height using the style object, not using classes, unless we use border-box -->
      <div
        class="sticky-side pointer-events-none bottom-0 left-0 top-0 box-border !bg-transparent"
        :class="[
          $attrs.class,
          'sticky-side:sticky-first:shadow-[-2px_0_theme(colors.sticky)]',
          'sticky-side:sticky-last:shadow-[-2px_0_theme(colors.sticky)]',
        ]"
        :style="{
          ...$attrs.style,
          borderRight: 'none',
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0,
          paddingRight: 0,
          marginLeft: 0,
          marginRight: 0,
          position: 'absolute',
          outline: 'none',
          minWidth: props.stickySideWidth + 'px',
        }"
      >
        <!-- @slot Aside content that remains sticky -->
        <slot name="aside"></slot>
      </div>
    </aside>
    <!-- sticky-content: the main content of the sticky row -->
    <!-- we should only fix border, padding, margin & height using the style object, not using classes, unless we use border-box -->
    <div
      v-bind="$attrs"
      class="sticky-content w-full"
      :style="{
        ...$attrs.style,
        paddingTop: $attrs.style?.paddingTop ?? $attrs.style?.padding ?? 0,
        paddingBottom: $attrs.style?.paddingBottom ?? $attrs.style?.padding ?? 0,
        marginTop: $attrs.style?.marginTop ?? $attrs.style?.margin ?? 0,
        marginBottom: $attrs.style?.marginBottom ?? $attrs.style?.margin ?? 0,
        contain: 'paint',
      }"
      ref="rowRef"
    >
      <!-- @slot Content that appears inside a sticky row -->
      <slot></slot>
    </div>
  </article>
</template>

<script setup lang="ts">
import { IClassStringArray as IClassStringArrayHack, elementReactiveRef } from '@common'
import { ref } from 'vue'
interface IClassStringArray extends IClassStringArrayHack {}
interface IProps {
  /**
   * Styles applied at the root of the row
   * This property replaces :class
   * ! Styles passed in :class are reoriented using v-bind="$attrs"
   * ! so that both the sticky part and the main content can share the same styles
   */
  classRoot?: IClassStringArray
  /**
   * Total offset from the left (in px) based on the node's current level on the tree
   */
  leftOffsetTotal?: number
  /**
   * Width of left side that remains sticky
   */
  stickySideWidth?: number
  /**
   * Boolean that indicates if responsive styles should be applied
   */
  isSticky?: boolean
}

const props = withDefaults(defineProps<IProps>(), {
  leftOffsetTotal: 0,
  stickySideWidth: 0,
  isSticky: false,
})

const rowRef = ref<HTMLElement>()
const rowRefReactive = elementReactiveRef(rowRef)

defineExpose({
  rowRef: rowRefReactive,
})
</script>

<script lang="ts">
export default {
  inheritAttrs: false,
}
</script>
