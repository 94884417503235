<template>
  <!-- tree-grid-spreadsheet -->
  <draggable
    tag="div"
    class="w-full"
    :class="[evaluatedGridStyles?.class_grid]"
    :ghost-class="evaluatedGridStyles?.class_row__ghost"
    :list="props.data?.dataset?.rows"
    :group="{ name: props.data?.drag_group }"
    handle=".handle"
    item-key="row_id"
    @start="isDragging = true"
    @end="isDragging = false"
  >
    <template #item="{ element, index }: { element: ITreeGridTableRow, index: number }">
      <tree-grid-row
        :index="index"
        :length="props.data?.dataset?.rows.length ?? 0"
        :grid-data="props.data"
        :grid-styles="evaluatedGridStyles"
        :row-data="element"
        :left-offset-total="props.leftOffsetTotal"
        :is-dragging="isDragging"
        @select="emit('select', $event)"
        @unselect="emit('unselect', $event)"
        @highlight="emit('highlight', $event)"
        @unhighlight="emit('unhighlight', $event)"
      >
        <template #actions-left="{ rowProps, isDragging, isSelected }">
          <!--
            @slot Actions that appear on the left side of the row
            @binding {IProps}   spreadsheet-props   All props available to the spreadsheet
            @binding {IProps}   row-props           All props available to the row
            @binding {boolean}  is-dragging         Boolean that indicates if a drag&drop is currently occuring
            @binding {boolean}  is-selected         Boolean indicating if current row is selected
          -->
          <slot
            name="row-actions-left"
            :spreadsheet-props="props"
            :row-props="rowProps"
            :is-dragging="isDragging"
            :is-selected="isSelected"
          ></slot>
        </template>
        <template v-if="$slots['cell-content']" #cell-content="{ rowProps, isDragging, isSelected }">
          <!--
            @slot Content of a grid cell
            @binding {IProps}   spreadsheet-props   All props available to the spreadsheet
            @binding {IProps}   row-props           All props available to the row
            @binding {boolean}  is-dragging         Boolean that indicates if a drag&drop is currently occuring
            @binding {boolean}  is-selected         Boolean indicating if current row is selected
          -->
          <slot
            name="cell-content"
            :spreadsheet-props="props"
            :row-props="rowProps"
            :is-dragging="isDragging"
            :is-selected="isSelected"
          ></slot>
        </template>
        <template #actions-right="{ rowProps, isDragging, isSelected }">
          <!--
            @slot Actions that appear on the right side of the row
            @binding {IProps}   spreadsheet-props   All props available to the spreadsheet
            @binding {IProps}   row-props           All props available to the row
            @binding {boolean}  is-dragging         Boolean that indicates if a drag&drop is currently occuring
            @binding {boolean}  is-selected         Boolean indicating if current row is selected
          -->
          <slot
            name="row-actions-right"
            :spreadsheet-props="props"
            :row-props="rowProps"
            :is-dragging="isDragging"
            :is-selected="isSelected"
          ></slot>
        </template>
      </tree-grid-row>
    </template>
  </draggable>
</template>

<script setup lang="ts">
import { ITreeGridTable as ITreeGridTableHack, IGridStyles as IGridStylesHack, ITreeGridTableRow as ITreeGridTableRowHack, evaluateStyles } from '@common'
import { computed, ref } from 'vue'
import draggable from 'vuedraggable'
interface ITreeGridTable extends ITreeGridTableHack {}
interface IGridStyles extends IGridStylesHack {}
interface ITreeGridTableRow extends ITreeGridTableRowHack {}
interface IProps {
  /**
   * Data representing current grid
   */
  data: ITreeGridTable
  /**
   * Total offset from the left (in px) based on the nodes's current level on the tree
   */
  leftOffsetTotal: number
  /**
   * Styles applied to the grid
   */
  gridStyles?: IGridStyles<IProps>
}

const props = withDefaults(defineProps<IProps>(), {})

const emit = defineEmits<{
  /**
   * Fires when row is selected
   * @param rowData Data representing selected row
   */
  (e: 'select', rowData: ITreeGridTableRow): void
  /**
   * Fires when row is unselected
   * @param rowData Data representing unselected row
   */
  (e: 'unselect', rowData: ITreeGridTableRow): void
  /**
   * Fires when row is highlighted
   * @param rowData Data representing highlighted row
   */
  (e: 'highlight', rowData: ITreeGridTableRow): void
  /**
   * Fires when row is unhighlighted
   * @param rowData Data representing unhighlighted row
   */
  (e: 'unhighlight', rowData: ITreeGridTableRow): void
}>()

const isDragging = ref(false)

const evaluatedGridStyles = computed(() => evaluateStyles(props.gridStyles, props))
</script>
