import { reactiveRef, ReactiveRef } from '@common'
import { useElementSize, useScroll, UseScrollReturn } from '@vueuse/core'
import { Ref } from 'vue'

/**
 * A reactive ref for html elements
 */
export interface ElementReactiveRef extends ReactiveRef<HTMLElement | undefined> {
  /**
   * Reactive width of element
   */
  width: number
  /**
   * Reactive height of element
   */
  height: number

  scroll: UseScrollReturn
}

/**
 * Returns a reactive object that safely preserves a synced copy of an HTMLElement ref inside it
 * @param originalRef The HTMLElement ref that is going to be preserved
 */
export function elementReactiveRef(originalRef?: Ref<HTMLElement | undefined>): ElementReactiveRef {
  const copyRef = reactiveRef(originalRef)

  const size = useElementSize(copyRef.getRef())

  Object.assign(copyRef, {
    width: size.width,
    height: size.height,
    scroll: useScroll(copyRef.getRef()),
  })

  return copyRef as ElementReactiveRef
}
