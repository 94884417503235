import { reactive, readonly, Ref, toRef } from 'vue'
import {
  ITreeGridItemState,
  ITreeGridItemStateBasic,
  ITreeGridState,
  ITreeGridItemStateInternal,
  elementReactiveRef,
  useRelativePosition,
} from '@common'

interface ITreeGridItemStateOptions<TData> extends ITreeGridItemStateBasic<TData> {
  /**
   * Reference to the HTMLElement of the item
   */
  itemRef?: Ref<HTMLElement | undefined> | undefined
  /**
   * The state object of the current tree
   */
  treeState: ITreeGridState
}

export function useTreeGridItemState<TData>({
  index,
  itemId,
  itemRef,
  itemData,
  treeState,
}: ITreeGridItemStateOptions<TData>): ITreeGridItemState<TData> {
  const itemReactiveRef = elementReactiveRef(itemRef)
  const contentTop = toRef(treeState, 'headerTotalHeight')
  const contentPosition = useRelativePosition(itemReactiveRef.getRef(), treeState.rootRef.getRef(), { top: contentTop })
  const overflowTop = toRef(treeState.headerRef, 'height')
  const overflowPosition = useRelativePosition(itemReactiveRef.getRef(), treeState.overflowRef.getRef(), {
    top: overflowTop,
  })

  const itemState = reactive<ITreeGridItemStateInternal<TData>>({
    index,
    itemId,
    itemData,
    itemRef: itemReactiveRef,
    contentPosition,
    overflowPosition,
    updateIndex,
    scrollOverflow,
    setCollapsedParent,
    removeCollapsedParent,
  })

  /**
   * @see ITreeGridItemState.setCollapsedParent
   */
  function setCollapsedParent(parent?: ITreeGridItemState) {
    itemState.collapsedParent = parent
  }
  /**
   * @see ITreeGridItemState.removeCollapsedParent
   */
  function removeCollapsedParent() {
    itemState.collapsedParent = undefined
  }

  /**
   * @see ITreeGridItemState.updateIndex
   */
  function updateIndex(index: number) {
    itemState.index = index
  }

  /**
   * @see ITreeGridItemState.scrollOverflow
   */
  function scrollOverflow(index: number, treeState: ITreeGridState) {
    const itemTop = itemState.overflowPosition?.getTop() ?? 0
    const itemBottom = itemState.overflowPosition?.getBottom() ?? 0
    const height = treeState.contentHeight ?? 0

    // The element is hidden above the overflow
    if (itemTop < 0) {
      if (index === 0) {
        treeState.overflowRef.value?.scroll({
          top: 0,
          behavior: 'smooth',
        })
      } else {
        treeState.overflowRef.value?.scrollBy({
          top: itemTop - height / 3,
          behavior: 'smooth',
        })
      }
    }
    // The element is hidden bellow the overflow
    else if (itemBottom > height) {
      const scrollHeight = treeState.overflowRef.value?.scrollHeight ?? 0
      if (index === treeState?.gridIndexLast) {
        treeState.overflowRef.value?.scroll({
          top: scrollHeight,
          behavior: 'smooth',
        })
      } else {
        // console.log(difference, height, 'Scroll 100')
        treeState.overflowRef.value?.scrollBy({
          top: itemBottom - (height * 2) / 3,
          behavior: 'smooth',
        })
      }
    }
  }

  //@ts-ignore
  return readonly(itemState)
}
