import "./main.css"
import { PlatformBuilder } from "@100-m/hauru/src/utils/platformBuilder"

import BaseApp from "@100-m/hauru/src/module"
// import BaseDesign from '@100-m/hauru/src/applications/design/module.js'
// import Switch from '@100-m/hauru/src/applications/switch/module'
// import AssetManagement from '@100-m/hauru/src/applications/asset-management/module'
// import ClientEdition from '@100-m/hauru/src/applications/client-edition/module'
import Poc from "@/applications/poc/module"
import auth0Login from "@100-m/hauru/src/features/auth/auth0-login.js"

// Object.entries(BaseDesign.pages).map(([path, module]) => (module.default.roles = ['anonymous', 'user']))
import clientConfig from "./config"
import config, { extendConfig } from "@100-m/hauru/src/config.js"
extendConfig(clientConfig)
const authConfig = auth0Login(config.auth)
const platform = new PlatformBuilder()
  .setAuth(authConfig)
  // .initFirebase(config.firebase)
  .addApp(BaseApp, Poc)
  // .addApp(BaseDesign)
  // .addApp(Switch)
  // .addApp(AssetManagement)
  // .addApp(ClientEdition)
  .build()
platform.mount("main")
