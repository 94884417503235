<script setup lang="ts">
import {
  IGridStyles,
  ITreeGridNode,
  ITreeGridTableCell,
  ITreeStyles,
  useTreeGridState,
} from "@common"
import { reactiveComputed } from "@vueuse/shared"
import { reactive, computed } from "vue"
import Operations from "../data/operations.json"
const treeStyles: ITreeStyles = {
  class_node: [
    _props =>
      _props.level === 0 ? 'sticky-bg-white' : 'rounded border-solid bg-sticky border-gray-300 sticky-bg-gray-100',
  ],
  style_node: {
    borderWidth: _props => (_props.level !== 0 ? '1px' : ''),
    marginRight: _props => (_props.level === 0 ? '' : '8px'),
  },
  class_node_header: [_props => (_props.level === 0 ? '' : 'h-8 !w-full')],
  sticky_side_width: 4,
  left_offset: _props => (_props.level === 0 ? 0 : 33),
}
const gridStyles: IGridStyles = reactive({
  class_grid: ['mr-2 first-of-type:mt-0'],
  class_row: [
    'bg-white text-gray-900 box-border cursor-pointer border-r border-solid border-gray-300',
    'sticky-first:rounded-t sticky-first:border-t sticky-last:rounded-b sticky-last:border-b',
    'shadow-gray-200 sticky-content:shadow-border-y sticky-first-not-last:sticky-content:shadow-border-b',
    'sticky-first:sticky-content:shadow-none sticky-last-not-first:sticky-content:shadow-border-t',
  ],
  style_row: { height: '32px', borderLeftWidth: '1px', borderRightWidth: '1px' },
  sticky_side_width: 4,
  class_row__selected: 'relative z-[110] !border-blue-300 sticky-content:bg-blue-50 sticky-content:!shadow-blue-300',
  class_row__hover: ['sticky-hover:bg-emerald-50'],
  class_row__ghost: 'bg-blue-50',
  class_cell: [
    'border-r border-solid border-y-gray-200 border-x-gray-100 last-of-type:border-r-0',
    'sticky-side:bg-white sticky-not-last:border-b sticky-side:last-of-type:border-r shadow-gray-500/10',
    'sticky-first:first-of-type:rounded-tl sticky-last:first-of-type:rounded-bl ',
    'sticky-hover:bg-emerald-50',
    'last-of-type:border-r-gray-200',
  ],
  class_cell__selected: [
    '!border-blue-100 sticky-side:!bg-blue-50 !border-b-blue-300 shadow-blue-300 sticky-side:shadow-border-t',
  ],
  class_cell__scrolled: ['sticky-side:last-of-type:drop-shadow-[4px_0_theme(colors.gray.500/10%)]'],
})
const state = useTreeGridState({
  allowSelect: true,
  columnSizes: [300].concat(Array(100).fill(200)),
  isSticky: true,
  showIndex: true,
  freezedColumns: 2,
})

const emptyRows = {
  row_id: null,
  cells: [{ value: ' ' }, { value: ' ' }],
  gridIndex: null,
}

function fillSums(node: ITreeGridNode | undefined, sums: ITreeGridTableCell[] = []) {
  const rows = node?.grid?.dataset?.rows
  const columns = node?.grid?.dataset?.columns
  rows?.forEach(
    row =>
      row.cells
        .slice(2)
        .forEach(
          (cell, i) => (sums[i] = { value: typeof cell.value === 'number' ? (sums[i]?.value || 0) + cell.value : '' }),
        ),
    [] as any,
  )
  if (columns && columns.findIndex(v => v.label === 'Prix') !== -1) sums[columns.findIndex(v => v.label === 'Prix') - 2].value = ''
  for (let child of node?.children ?? []) {
    fillSums(child, sums)
  }
  return sums
}

const toSum = (node: ITreeGridNode | undefined, name: string) => {
  const sums = fillSums(node)
  return {
    row_id: null,
    cells: [{ value: name }, { value: ' ' }].concat(sums),
    gridIndex: null,
  }
}

function countRows(node: ITreeGridNode | undefined) {
  let count = 0
  for (const child of node?.children ?? []) {
    count += countRows(child)
  }
  count += node?.grid?.dataset?.rows?.length ?? 0
  return count
}

const toVisage = data => {
  if (Array.isArray(data)) {
    const columns = storyStateTree.columns
    return {
      grid: {
        dataset: {
          columns: columns.map((k, i) => ({ col_id: i, label: k })),
          rows: data.map((row, i) => ({
            row_id: i,
            cells: columns.map((k, i) => ({ value: row[k] })),
          })),
        },
      },
    }
  }
  return {
    children: Object.entries(data).map(([name, v]) => ({
      name,
      ...toVisage(v),
    })),
  }
}

// TODO: Define source data model instead of ITreeGridNode<any>
function transformInput(input: ITreeGridNode, level = 0): ITreeGridNode {
  for (let row of input?.grid?.dataset?.rows ?? []) {
    for (let cell of row?.cells ?? []) {
      if (cell?.type === 'secondary') cell.cell_styles = { class_cell: 'text-gray-500' }
    }
  }

  const transformedData: ITreeGridNode = {
    ...input,
    children: input?.children?.map(i => transformInput(i, level + 1)),
    source_data: input,
  }

  return transformedData
}

const storyStateTree = reactive({
  trackPositions: true,
  raw: Operations,
  data: null as ITreeGridNode | null,
  columns: ['Opération', 'Produit', 'Engagé', 'Quantité', 'Prix', 'Investi', 'Reçu', 'Rappelable', 'Appelé', 'Engagement Résiduel', 'Commentaires', 'Frais de gestion', 'Autres Frais', 'Taxes', 'Plus Value', 'Carried Interest', 'Dividendes', 'Intérêts', 'Autres Produits'],
  sort: [],
  group: ['Investissement', 'Souscripteur', 'Programme'],
  filter: $root.filters.__.filter((v, k) => v && v.filter().length && k !== "domain").__.map(v => v[0]),
  search: '',
})

storyStateTree.data = reactiveComputed(() => {
  // update_query(storyStateTree.filter)
  console.time()
  const { group, sort, filter, search } = storyStateTree
  const filter_entries = Object.entries(filter)
  const raw = Operations.slice()
  const searched = search.length
    ? raw.filter(row => Object.values(row).some(v => v?.toLowerCase?.().includes(search.toLowerCase())))
    : raw
  const filtered = filter_entries.length
    ? searched.filter(row => filter_entries.every(([k, v]) => ("" + row[k]).toLowerCase().includes(v.toLowerCase())))
    : searched
  const sorted = filtered.sort(group.concat(sort))
  const grouped = group.length ? sorted.group(group) : sorted
  const grid = toVisage(grouped)
  const enriched = transformInput(grid)
  console.timeEnd()
  return enriched
})

</script>
<script lang="ts">
export const additions = { icon: "ic_dashboard" }
</script>

<template>
  <div class="row">
    <h1 class="text-3xl font-bold">{{ t.reporting }}</h1>
    <div class="grow"></div>
    <div v-for="(item, name) in storyStateTree.filter">
      <span
        class="cursor-pointer inline-flex items-center rounded-md bg-indigo-100 px-2.5 py-0.5 text-lg text-indigo-800"
        @click="storyStateTree.filter[name] = '';window.update_query({ [name]: '' })"
        v-if="storyStateTree.filter[name]"
      >
        <span>{{ name }}:</span>
        <span class="ml-1 font-medium">{{ item }}</span>
        <svg class="m-2 h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
          <path stroke-linecap="round" stroke-width="1.5" d="M1 1l6 6m0-6L1 7" />
        </svg>
      </span>
    </div>
  </div>
  <div class="block p-1 h-[calc(100vh-160px)]">
    <div class="flex gap-1">
      <button class="[[open]_&]:bg-emerald-200 flex cursor-pointer select-none items-center gap-1 rounded px-2 py-1 font-medium text-gray-700 hover:bg-emerald-100" :class="{ 'bg-emerald-200': JSON.stringify(storyStateTree.columns) === JSON.stringify(['Opération', 'Produit', 'Quantité', 'Prix', 'Investi','Plus Value', 'Reçu',  'Commentaires', 'Frais de gestion', 'Autres Frais', 'Taxes', 'Carried Interest', 'Dividendes', 'Intérêts', 'Autres Produits']) }" @click="storyStateTree.columns = ['Opération', 'Produit', 'Quantité', 'Prix', 'Investi', 'Plus Value', 'Reçu', 'Commentaires', 'Frais de gestion', 'Autres Frais', 'Taxes',  'Carried Interest', 'Dividendes', 'Intérêts', 'Autres Produits']">上市股票视图</button>
      <button class="[[open]_&]:bg-emerald-200 flex cursor-pointer select-none items-center gap-1 rounded px-2 py-1 font-medium text-gray-700 hover:bg-emerald-100" :class="{ 'bg-emerald-200': JSON.stringify(storyStateTree.columns) === JSON.stringify(['Opération', 'Produit', 'Quantité', 'Investi', 'Plus Value','Reçu',  'Commentaires', 'Frais de gestion', 'Autres Frais', 'Taxes', 'Carried Interest', 'Dividendes', 'Intérêts', 'Autres Produits']) }" @click="storyStateTree.columns = ['Opération', 'Produit', 'Quantité', 'Investi', 'Plus Value', 'Reçu', 'Commentaires', 'Frais de gestion', 'Autres Frais', 'Taxes',  'Carried Interest', 'Dividendes', 'Intérêts', 'Autres Produits']">未上市股票查看</button>
      <button class="[[open]_&]:bg-emerald-200 flex cursor-pointer select-none items-center gap-1 rounded px-2 py-1 font-medium text-gray-700 hover:bg-emerald-100" :class="{ 'bg-emerald-200': JSON.stringify(storyStateTree.columns) === JSON.stringify(['Opération', 'Produit', 'Engagé',  'Investi', 'Reçu', 'Rappelable', 'Appelé', 'Engagement Résiduel', 'Commentaires', 'Frais de gestion', 'Autres Frais', 'Taxes', 'Plus Value', 'Carried Interest', 'Dividendes', 'Intérêts', 'Autres Produits']) }" @click="storyStateTree.columns = ['Opération', 'Produit', 'Engagé',  'Investi', 'Reçu', 'Rappelable', 'Appelé', 'Engagement Résiduel', 'Commentaires', 'Frais de gestion', 'Autres Frais', 'Taxes', 'Plus Value', 'Carried Interest', 'Dividendes', 'Intérêts', 'Autres Produits']">私募股权观点</button>
    </div>
    <tree-grid
      class="w-full"
      :state="state"
      :data="storyStateTree.data"
      :track-positions="storyStateTree.trackPositions"
      :tree-styles="treeStyles"
      @overflow-scroll="state.updateScrollTop"
    >
      <template #tree-header-actions>
        <tree-grid-header-actions>
          <div class="flex items-center gap-1">
            <agache-dropdown class="shrink-0">
              <template #title>
                <div
                  class="[[open]_&]:bg-emerald-200 flex cursor-pointer select-none items-center gap-1 rounded px-2 py-1 font-medium text-gray-700 hover:bg-emerald-100"
                  :class="{
                    'bg-emerald-50': Object.keys(storyStateTree.raw[0]).length - storyStateTree.columns.length,
                  }"
                >
                  <dynamic-icon i="ic-round-visibility-off" class="!h-4 !transform-none"></dynamic-icon>
                  <div v-if="Object.keys(storyStateTree.raw[0]).length - storyStateTree.columns.length">
                    {{ Object.keys(storyStateTree.raw[0]).length - storyStateTree.columns.length }} Hidden column{{
                      Object.keys(storyStateTree.raw[0]).length - storyStateTree.columns.length > 1 ? "s" : ""
                    }}
                  </div>
                  <div v-else>Hide columns</div>
                </div>
              </template>
              <agache-list :gather-selected-on-top="false" :all="Object.keys(storyStateTree.raw[0])" :selected="storyStateTree.columns" name="columns" />
            </agache-dropdown>
            <agache-dropdown class="shrink-0">
              <template #title>
                <div
                  class="[[open]_&]:bg-emerald-200 flex cursor-pointer select-none items-center gap-1 rounded px-2 py-1 font-medium text-gray-700 hover:bg-emerald-100"
                  :class="{ 'bg-emerald-50': Object.values(storyStateTree.filter).filter().length }"
                >
                  <dynamic-icon i="mdi-filter-outline" class="!h-[14px] !transform-none"></dynamic-icon>
                  <div v-if="Object.values(storyStateTree.filter).filter().length">
                    {{ Object.values(storyStateTree.filter).filter().length }} Filter{{
                      Object.values(storyStateTree.filter).filter().length > 1 ? "s" : ""
                    }}
                  </div>
                  <div v-else>Filter</div>
                </div>
              </template>
              <template v-for="(key, index) in Object.keys(storyStateTree.raw[0]).filter()">
                <button class="absolute right-0 h-8 w-8 flex items-center justify-center" @click="storyStateTree.filter[key] = ''">
                  <dynamic-icon i="mdi-trash" class="opacity-50 !transform-none"></dynamic-icon>
                </button>
                <input
                  class="flex w-full cursor-pointer select-none h-8 px-3 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                  :placeholder="key"
                  v-model="storyStateTree.filter[key]"
                />
              </template>
            </agache-dropdown>
            <agache-dropdown class="shrink-0">
              <template #title>
                <div
                  class="[[open]_&]:bg-emerald-200 flex cursor-pointer select-none items-center gap-1 rounded px-2 py-1 font-medium text-gray-700 hover:bg-emerald-100"
                  :class="{ 'bg-emerald-50': storyStateTree.group.length }"
                >
                  <dynamic-icon i="mdi-view-list-outline" class="!transform-none"></dynamic-icon>
                  <div v-if="storyStateTree.group.length">
                    {{ storyStateTree.group.length }} Group{{ storyStateTree.group.length > 1 ? "s" : "" }}
                  </div>
                  <div v-else>Group</div>
                </div>
              </template>
              <agache-list :all="Object.keys(storyStateTree.raw[0])" :selected="storyStateTree.group" name="group" />
            </agache-dropdown>
            <agache-dropdown class="shrink-0">
              <template #title>
                <div
                  class="[[open]_&]:bg-emerald-200 flex cursor-pointer select-none items-center gap-1 rounded px-2 py-1 font-medium text-gray-700 hover:bg-emerald-100"
                  :class="{ 'bg-emerald-50': storyStateTree.sort.length }"
                >
                  <dynamic-icon i="mdi-sort" class="!h-[14px] !transform-none"></dynamic-icon>
                  <div v-if="storyStateTree.sort.length">
                    {{ storyStateTree.sort.length }} Sort{{ storyStateTree.sort.length > 1 ? "s" : "" }}
                  </div>
                  <div v-else>Sort</div>
                </div>
              </template>
              <agache-list
                :all="
                  Object.keys(storyStateTree.raw[0]).map((k) => (storyStateTree.sort.includes('-' + k) ? '-' + k : k))
                "
                :selected="storyStateTree.sort"
                name="sort"
              >
                <template #up="{ element }">
                  <div
                    class="flex h-full flex-1 items-center gap-1"
                    v-if="element.startsWith('-')"
                    @click.stop="
                      storyStateTree.sort = storyStateTree.sort.map((k) => (k === element ? element.slice(1) : k))
                    "
                  >
                    <dynamic-icon i="mdi-sort-ascending"></dynamic-icon>
                    {{ element.slice(1) }}
                  </div>
                  <div
                    class="flex h-full flex-1 items-center gap-1"
                    v-else
                    @click.stop="
                      storyStateTree.sort = storyStateTree.sort.map((k) => (k === element ? '-' + element : k))
                    "
                  >
                    <dynamic-icon i="mdi-sort-descending"></dynamic-icon>
                    {{ element }}
                  </div>
                </template>
              </agache-list>
            </agache-dropdown>
          </div>
          <div class="grow"></div>
          <div>
            <input
              type="search"
              placeholder="Find in view"
              class="w-[240px] h-8 m-1 grow-0 border"
              v-model="storyStateTree.search"
            />
          </div>
          <div class="w-5"></div>
        </tree-grid-header-actions>
      </template>
      <template
        #node-header="{ isExpanded, toggleExpanded, treeGridProps: { data: treeNodeData, level, leftOffsetTotal } }"
      >
        <template v-if="level > 0">
          <tree-grid-row
            class="!w-full font-medium"
            :grid-styles="{
              class_row: '',
              class_cell: [
                'first-of-type:ml-[34px] sticky-side:first-of-type:ml-0',
                'sticky-side:bg-sticky sticky-side:last-of-type:border-r',
                'sticky-side:last-of-type:border-r-gray-200',
              ],
              class_cell__scrolled: ['sticky-side:last-of-type:drop-shadow-[4px_0_theme(colors.gray.500/10%)]'],
              style_row: { height: '32px' },
              sticky_side_width: 0,
            }"
            :row-data="toSum(treeNodeData, treeNodeData.name)"
            :index-to-show="countRows(treeNodeData)"
            :left-offset-total="leftOffsetTotal + 32 + 3"
            :is-dragging="false"
            :first-column-adjust="-3"
          >
            <template #actions-left>
              <tree-sticky-row-buttons
                class="flex bg-transparent"
                position="left"
                :style="{ left: leftOffsetTotal + 1 + 'px' }"
                :styles="{
                  class_root: ['top-px'],
                  class_main: ['bg-sticky'],
                }"
              >
                <div
                  class="flex cursor-pointer select-none items-center rounded m-1 font-medium text-gray-700 hover:bg-emerald-200"
                  @click="toggleExpanded()"
                >
                  <dynamic-icon 
                    i="ic-baseline-arrow-drop-down" 
                    :style="{width: '2em'}" 
                    class="!h-6 -mx-1 transition-transform"
                    :class="[isExpanded ? '' : 'rotate-[-90deg]']"
                  ></dynamic-icon>
                </div>
              </tree-sticky-row-buttons>
            </template>
          </tree-grid-row>
          <div class="grow"></div>
        </template>
      </template>
      <template #node-footer="{ isExpanded, treeGridProps: { level, leftOffsetTotal } }">
        <tree-grid-row
          v-if="level > 0 && isExpanded"
          class="!w-full font-medium"
          :grid-styles="{
            class_row: '',
            class_cell: [
              'first-of-type:ml-[34px] sticky-side:first-of-type:ml-0',
              'sticky-side:bg-sticky sticky-side:last-of-type:border-r',
              'sticky-side:last-of-type:border-r-gray-200',
            ],
            class_cell_content: 'hidden',
            class_cell__scrolled: ['sticky-side:last-of-type:drop-shadow-[4px_0_theme(colors.gray.500/10%)]'],
            style_row: { height: '8px' },
            sticky_side_width: 0,
          }"
          :row-data="emptyRows"
          :left-offset-total="leftOffsetTotal + 32 + 3"
          :is-dragging="false"
          :first-column-adjust="-3"
        >
        </tree-grid-row>
      </template>
      <template #below-node="{ treeGridProps: { level, leftOffsetTotal } }">
        <tree-grid-row
          v-if="level > 0"
          class="!w-full font-medium"
          :grid-styles="{
            class_row: '',
            class_cell: [
              'first-of-type:ml-[34px] sticky-side:first-of-type:ml-0',
              'sticky-side:bg-sticky sticky-side:last-of-type:border-r',
              'sticky-side:last-of-type:border-r-gray-200',
            ],
            class_cell_content: 'hidden',
            class_cell__scrolled: ['sticky-side:last-of-type:drop-shadow-[4px_0_theme(colors.gray.500/10%)]'],
            style_row: { height: '8px' },
            sticky_side_width: 0,
          }"
          :row-data="emptyRows"
          :left-offset-total="leftOffsetTotal + 32 + 3"
          :is-dragging="false"
          :first-column-adjust="-3"
        >
        </tree-grid-row>
      </template>
      <template #spreadsheet="{ leftOffsetTotal, toggleParents, treeGridProps: { data: treeNodeData } }">
        <tree-grid-spreadsheet
          :data="treeNodeData.grid"
          :grid-styles="gridStyles"
          :left-offset-total="leftOffsetTotal"
          @select="toggleParents(true)"
        >
        </tree-grid-spreadsheet>
      </template>
    </tree-grid>
  </div>
</template>

<style>
* {
  font-variant: tabular-nums;
}
</style>
