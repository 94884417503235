<style>
.sunbirst { min-width: 400px; }
.sunbirst .plot-pie path:nth-child(5n + 1) { fill: rgb(250,212,98); }
.sunbirst .plot-pie path:nth-child(5n + 2) { fill: rgb(213,188,83); }
.sunbirst .plot-pie path:nth-child(5n + 3) { fill: rgb(181,160,70); }
.sunbirst .plot-pie path:nth-child(5n + 4) { fill: rgb(148,131,58); }
.sunbirst .plot-pie path:nth-child(5n + 5) { fill: rgb(131,116,51); }
.sunbirst .pie-legend :nth-child(5n + 1) > .color { background: rgb(250,212,98); }
.sunbirst .pie-legend :nth-child(5n + 2) > .color { background: rgb(213,188,83); }
.sunbirst .pie-legend :nth-child(5n + 3) > .color { background: rgb(181,160,70); }
.sunbirst .pie-legend :nth-child(5n + 4) > .color { background: rgb(148,131,58); }
.sunbirst .pie-legend :nth-child(5n + 5) > .color { background: rgb(131,116,51); }
.block:nth-child(2) .sunbirst .plot-pie path:nth-child(5n + 1) { fill: rgb(228,100,70); }
.block:nth-child(2) .sunbirst .plot-pie path:nth-child(5n + 2) { fill: rgb(193,85,59); }
.block:nth-child(2) .sunbirst .plot-pie path:nth-child(5n + 3) { fill: rgb(164,72,50); }
.block:nth-child(2) .sunbirst .plot-pie path:nth-child(5n + 4) { fill: rgb(139,61,43); }
.block:nth-child(2) .sunbirst .plot-pie path:nth-child(5n + 5) { fill: rgb(119,52,37); }
.block:nth-child(2) .sunbirst .pie-legend :nth-child(5n + 1) > .color { background: rgb(228,100,70); }
.block:nth-child(2) .sunbirst .pie-legend :nth-child(5n + 2) > .color { background: rgb(193,85,59); }
.block:nth-child(2) .sunbirst .pie-legend :nth-child(5n + 3) > .color { background: rgb(164,72,50); }
.block:nth-child(2) .sunbirst .pie-legend :nth-child(5n + 4) > .color { background: rgb(139,61,43); }
.block:nth-child(2) .sunbirst .pie-legend :nth-child(5n + 5) > .color { background: rgb(119,52,37); }
.block:nth-child(3) .sunbirst .plot-pie path:nth-child(5n + 1) { fill: rgb(133,161,238); }
.block:nth-child(3) .sunbirst .plot-pie path:nth-child(5n + 2) { fill: rgb(113,137,202); }
.block:nth-child(3) .sunbirst .plot-pie path:nth-child(5n + 3) { fill: rgb(96,117,172); }
.block:nth-child(3) .sunbirst .plot-pie path:nth-child(5n + 4) { fill: rgb(82,99,147); }
.block:nth-child(3) .sunbirst .plot-pie path:nth-child(5n + 5) { fill: rgb(48,57,83); }
.block:nth-child(3) .sunbirst .pie-legend :nth-child(5n + 1) > .color { background: rgb(133,161,238); }
.block:nth-child(3) .sunbirst .pie-legend :nth-child(5n + 2) > .color { background: rgb(113,137,202); }
.block:nth-child(3) .sunbirst .pie-legend :nth-child(5n + 3) > .color { background: rgb(96,117,172); }
.block:nth-child(3) .sunbirst .pie-legend :nth-child(5n + 4) > .color { background: rgb(82,99,147); }
.block:nth-child(3) .sunbirst .pie-legend :nth-child(5n + 5) > .color { background: rgb(48,57,83); }
.block:nth-child(4) .sunbirst .plot-pie path:nth-child(5n + 1) { fill: rgb(22,152,137); }
.block:nth-child(4) .sunbirst .plot-pie path:nth-child(5n + 2) { fill: rgb(19,121,108); }
.block:nth-child(4) .sunbirst .plot-pie path:nth-child(5n + 3) { fill: rgb(19,121,108); }
.block:nth-child(4) .sunbirst .plot-pie path:nth-child(5n + 4) { fill: rgb(19,121,108); }
.block:nth-child(4) .sunbirst .plot-pie path:nth-child(5n + 5) { fill: rgb(19,121,108); }
.block:nth-child(4) .sunbirst .pie-legend :nth-child(5n + 1) > .color { background: rgb(22,152,137); }
.block:nth-child(4) .sunbirst .pie-legend :nth-child(5n + 2) > .color { background: rgb(19,121,108); }
.block:nth-child(4) .sunbirst .pie-legend :nth-child(5n + 3) > .color { background: rgb(19,121,108); }
.block:nth-child(4) .sunbirst .pie-legend :nth-child(5n + 4) > .color { background: rgb(19,121,108); }
.block:nth-child(4) .sunbirst .pie-legend :nth-child(5n + 5) > .color { background: rgb(19,121,108); }

.pie-wrapper {
  position: relative;
  width: 200px;
  margin-top: 8px;
  margin-bottom: 8px;
  margin-left: auto;
}
.breadcrumb {
  margin-top: -8px;
}
.plot-pie {
  min-height: 200px;
  max-height: 200px;
  min-width: 200px;
  max-width: 200px;
}
.pie-center {
  position: absolute;
  top: 60px;
  bottom: 60px;
  left: 60px;
  right: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: 1.2;
}
.pie-center .value {
  font-size: 150%;
  color: var(--title);
}
.label {
  text-transform: uppercase;
  font-size: 12px;
  color: var(--title);
}
.breadcrumb .label:not(:first-child)::before {
  content: ">";
  margin: 4px;
}
.no-legend .legend {
  display: none;
}
.plot-pie .legend {
  margin: 0 -60px;
  font-size: 10px;
  flex-wrap: wrap;
  justify-content: center;
}
.pie-legend {
  margin: auto;
  margin-left: 10px;
}
.pie-legend > .row {
  align-items: center;
  width: 100%;
  cursor: pointer;
}
.pie-legend > .row > *:last-child {
  padding-left: 4px;
}
.color {
  height: 18px;
  width: 18px;
  border-radius: 99px;
  margin: 8px;
}
.transluent {
  fill-opacity: 0.2;
}
</style>

<template>
  <div class="column sunbirst">
    <div class="row">
      <div class="pie-wrapper">
        <div class="row between">
          <svg class="plot-pie" viewBox="-100 -100 200 200">
            <path
              :d="window.d3.arc()(slice.arc)"
              :class="[slice.k, { transluent: selected.k !== 'total' }]"
              @mouseenter="hover = slice"
              @mouseleave="hover = {}"
              @click="$emit('plotclick', slice.k)"
              v-for="slice in slices"
            ></path>
            <path
              :d="window.d3.arc()(slice.arc)"
              :class="[slice.k,'lvl1', { transluent: selected2.k && selected2.k !== slice.k }]"
              @mouseenter="hover2 = slice"
              @mouseleave="hover2 = {}"
              @click="$emit('plotclick_sub', slice.k)"
              v-for="slice in slices2"
            ></path>
          </svg>
          <div class="pie-center" @click="$emit('plotclick_center')">
            <div class="value" :tt="format(',.2f€')(+selected.v)">
              {{
                (selected2.k && format(metadata.format)(+selected2.v)) ||
                (selected.k && format(metadata.format)(+selected.v))
              }}
            </div>
            <div class="label" v-if="selected.k !== 'total'">
              {{ t[selected2.k] || selected2.k || t[selected.k] || selected.k }}
            </div>
          </div>
        </div>
      </div>
      <div class="pie-legend">
        <div
          class="row"
          :class="label"
          @click="metadata.selection ? $emit('plotclick_sub', label) : $emit('plotclick', label)"
          v-for="(value, label) in legend"
        >
          <div class="color"></div>
          <div class="column">
            <div class="label">{{ t[label] || label }}</div>
            <div class="label" style="color: var(--secondary); font-weight: 200">
              {{ format(metadata.format)(value) }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: ["data", "metadata"],
  emits: ["plotclick", "plotclick_sub", "plotclick_center"],
  data() {
    return {
      hover: {},
      hover2: {},
    }
  },
  computed: {
    selected() {
      const { selection } = this.metadata
      let v = this.data[selection]?.__.reduce((acc, v, k) => acc + v, 0)
      return {
        k: selection || "total",
        v: (v || this.total).toFixed(0),
      }
    },
    selected2() {
      const { selection, selection2 } = this.metadata
      let v = this.data[selection]?.[selection2]
      if (!v) return {}
      return {
        k: selection2,
        v: v.toFixed(0),
      }
    },
    legend() {
      if (this.selected.k === "total") return this.d
      if (this.data[this.selected.k][{}]) return this.d
      return this.data[this.selected.k]
    },
    total() {
      return this.d?.__.reduce((acc, v) => acc + v, 0)
    },
    d() {
      return this.data?.__.reduce((acc, v, k) => {
        if (type(v) === "object") acc[k] = v?.__.reduce((acc, v, k) => acc + v, 0)
        else acc[k] = v
        return acc
      }, {})
    },
    d2() {
      return this.data?.__.reduce((acc, v, k) => {
        if (type(v) === "object") v?.__.map((v, k) => (acc[k] = v))
        return acc
      }, {})
    },
    slices() {
      return this.d?.__.map((v, k) => {
        const slice = { v, k, lvl: 0 }
        return slice
      })?.__.map((slice, k, arr, ds) => {
        const i = ds?.__.keys().findIndex(k)
        const prev = i !== 0 ? ds[ds?.__.keys()[i - 1]].arc.endAngle : 0
        const next = prev + (2 * Math.PI * slice.v) / this.total
        slice.arc = { innerRadius: 55, outerRadius: 90, startAngle: prev + (2 * Math.PI) / 360, endAngle: next }
        if (this.selected.k === slice.k) slice.arc.outerRadius -= 21
        return slice
      })
    },
    slices2() {
      return this.d2?.__.map((v, k) => {
        const slice = { v, k, lvl: 1 }
        return slice
      })?.__.map((slice, k, arr, ds) => {
        const i = ds?.__.keys().findIndex(k)
        const prev = i !== 0 ? ds[ds?.__.keys()[i - 1]].arc.endAngle : 0
        const next = prev + (2 * Math.PI * slice.v) / this.total
        slice.arc = { innerRadius: 0, outerRadius: 0, startAngle: prev + (2 * Math.PI) / 360, endAngle: next }
        if (this.selected.k === this.data.__.find((grp) => grp?.__.keys().find((k) => k === slice.k))) {
          slice.arc.innerRadius += 70
          slice.arc.outerRadius += 100
        }
        return slice
      })
    },
  },
}
</script>
