<script setup>
import { ref } from "vue"
// import Programs from "../data/programs.json"
</script>
<script>
export const additions = { icon: "ic_description" }
</script>

<template>
  <h1 class="text-3xl font-bold">{{ t["prog-card"] }}</h1>
</template>
