import { reactive, ref, Ref, watch, WatchStopHandle } from 'vue'

/**
 * A reactive object that safely preserves a synced copy of a ref inside it.
 */
export interface ReactiveRef<T = any> {
  /**
   * Current value contained inside the ref
   */
  value: T
  /**
   * Returns a synced copy of the source ref.
   * This method preserves and returns the same ref, even if we replace the source ref,
   * thus allowing a continuous flow of reactivity when changing from one source ref to another.
   */
  getRef: () => Ref<T>
  /**
   * Replaces source ref with another one.
   * @param newRef The new ref that replaces the previous one
   */
  replaceRef: (newRef: Ref<T>) => void
  /**
   * Stops the watcher that keeps the preserved ref in sync with the source ref.
   */
  stop: WatchStopHandle
}

/**
 * Returns a reactive object that safely preserves a synced copy of a source ref inside it.
 * @param sourceRef The ref whose synced copy is going to be preserved
 */
export function reactiveRef<T = any>(sourceRef?: Ref<T>): ReactiveRef<T> {
  const copyRef = ref()
  let stop: WatchStopHandle = () => undefined

  const store = reactive<ReactiveRef>({
    value: copyRef?.value,
    getRef: () => copyRef,
    replaceRef,
    stop,
  })

  if (sourceRef) replaceRef(sourceRef)

  function replaceRef(newRef: Ref<T>) {
    stop()

    stop = watch(
      newRef,
      newValue => {
        store.value = newValue
        copyRef.value = newValue
      },
      {
        flush: 'sync',
        immediate: true,
      },
    )
  }

  return store
}
