import type { IGridColumn } from '@common'
import {
  ITreeGridItemState,
  ITreeGridState,
  ITreeGridStateOptions,
  useTreeGridItemState,
  ITreeGridNode,
  ITreeGridStateInternal,
  elementReactiveRef,
  ITreeGridTableRow,
  isNumeric,
} from '@common'
import { InjectionKey, reactive, readonly, watchEffect } from 'vue'

/**
 * Reading speed of a state in an array of states (ex. selected) is costly (log(n))
 * if we don't know the position of the state we are looking for in the array.
 * To optimise reading speed of states whose position in the array we don't know
 * we populate a simple hash map (log(1)) with each state we add to our array
 * @key itemIndex Zero-based index of the item whose state we want to read
 */
export type ITreeGridItemStateMap = {
  [itemIndex: number]: ITreeGridItemState
}

export function useTreeGridState({
  allowSelect = false,
  allowMultiSelect = false,
  freezedColumns = 0,
  columnSizes = [],
  minColumnSize = 100,
  minIndexColumnSize = 40,
  isSticky = false,
  showIndex = false,
  isPercent = false,
}: Partial<ITreeGridStateOptions> = {}): ITreeGridState {
  /**
   * Array of currently selected rows
   */
  const selected: ITreeGridItemState<ITreeGridTableRow>[] = reactive([])
  /**
   * Maps selected row indexes to their selected state
   */
  const selectedMap: ITreeGridItemStateMap = reactive({})
  /**
   * Array of currently highlighted rows
   */
  const highlighted: ITreeGridItemState<ITreeGridTableRow>[] = reactive([])
  /**
   * Maps highlighted row indexes to their highlighted state
   */
  const highlightedMap: ITreeGridItemStateMap = reactive({})

  /**
   * Array of currently collapsed nodes
   */
  const collapsed: ITreeGridItemState<ITreeGridNode>[] = reactive([])
  /**
   * Maps collapsed node indexes to their collapsed state
   */
  const collapsedMap: ITreeGridItemStateMap = reactive({})

  const indexToRowId: { [key: number]: number } = reactive({})
  const rowIdToIndex: { [key: number]: number } = reactive({})

  const rootRef = elementReactiveRef()
  const overflowRef = elementReactiveRef()
  const headerRef = elementReactiveRef()

  const state = reactive<ITreeGridStateInternal>({
    allowSelect,
    allowMultiSelect,
    columnSizes,
    minColumnSize,
    minIndexColumnSize,
    isSticky,
    freezedColumns,
    showIndex,
    selected,
    highlighted,
    collapsed,
    scrollTop: 0,
    rootRef,
    overflowRef,
    headerRef,
    isPercent,
    updateScrollTop,
    getRowId,
    getRowIndex,

    isSelected: (rowIndex: number) => is(rowIndex, selectedMap),
    isSelectedById: (rowId: number) => is(getRowIndex(rowId), selectedMap),
    getSelected: (rowIndex: number) => get(rowIndex, selectedMap),
    getSelectedById: (rowId: number) => get(getRowIndex(rowId), selectedMap),
    addSelected: (rowIndex: number) => add(rowIndex, selected, selectedMap, allowMultiSelect),
    addSelectedById: (rowId: number) => add(getRowIndex(rowId), selected, selectedMap, allowMultiSelect),
    updateSelectedState: (rowIndex: number, state: ITreeGridItemState) =>
      updateState(rowIndex, selected, selectedMap, state),
    removeSelected: (rowIndex: number) => remove(rowIndex, selected, selectedMap),
    removeSelectedById: (rowId: number) => remove(getRowIndex(rowId), selected, selectedMap),
    toggleSelected: (rowIndex: number) => toggle(rowIndex, selected, selectedMap, allowMultiSelect),
    toggleSelectedById: (rowId: number) => toggle(getRowIndex(rowId), selected, selectedMap, allowMultiSelect),
    emptySelected: () => empty(selected, selectedMap),
    selectNext: () => next(selected, selectedMap),
    selectPrevious: () => previous(selected, selectedMap),

    isHighlighted: (rowIndex: number) => is(rowIndex, highlightedMap),
    isHighlightedById: (rowId: number) => is(getRowIndex(rowId), highlightedMap),
    getHighlighted: (rowIndex: number) => get(rowIndex, highlightedMap),
    getHighlightedById: (rowId: number) => get(getRowIndex(rowId), highlightedMap),
    setHighlighted: (rowIndexList: number[]) => set(rowIndexList, highlighted, highlightedMap),
    setHighlightedById: (rowIdList: number[]) =>
      set(
        rowIdList.map(id => getRowIndex(id)),
        highlighted,
        highlightedMap,
      ),
    updateHighlightedState: (rowIndex: number, state: ITreeGridItemState) =>
      updateState(rowIndex, highlighted, highlightedMap, state),
    emptyHighlighted: () => empty(highlighted, highlightedMap),
    calculateIndexesAndHeaders,

    addCollapsed: (nodeIndex: number, nodeState: ITreeGridItemState) =>
      add(nodeIndex, collapsed, collapsedMap, true, nodeState),
    removeCollapsed: (nodeIndex: number) => remove(nodeIndex, collapsed, collapsedMap),
  })

  watchEffect(() => {
    if (state.overflowRef.height) state.contentHeight = state.overflowRef.height - (headerRef.height ?? 0)
    if (state.contentHeight && state.rootRef.height)
      state.headerTotalHeight = state.rootRef.height - state.contentHeight
  })

  /**
   * @see ITreeGridState.getRowId
   */
  function getRowId(rowIndex: number) {
    return indexToRowId[rowIndex]
  }

  /**
   * @see ITreeGridState.getRowIndex
   */
  function getRowIndex(rowId: number) {
    return rowIdToIndex[rowId]
  }

  /**
   * Returns the state of the item indicated
   * @param itemIndex Zero-based index of the item whose state will be returned
   * @param map Hash map of states that will be used to search for the state of the item
   */
  function get(itemIndex: number, map: ITreeGridItemStateMap): ITreeGridItemState | undefined {
    return map?.[itemIndex]
  }

  /**
   * Returns a boolean that indicates whether an item has a certain state
   * @param itemIndex Zero-based index of the item whose state will be returned
   * @param map Hash map of states that will be used to search for the state of the item
   */
  function is(itemIndex: number, map: ITreeGridItemStateMap): boolean {
    return !!map?.[itemIndex]
  }

  /**
   * Adds indicated item to an array and its hash map of states
   * @param itemIndex Zero-based index of the item to be added
   * @param array Array of states in which we will add the state of the indicated item
   * @param map Hash map of states in which we will add the state of the indicated item
   * @param allowMultiple Boolean that indicates if multiple items are allowed to have the same state simultaneously
   */
  function add(
    itemIndex: number,
    array: ITreeGridItemState[],
    map: ITreeGridItemStateMap,
    allowMultiple = true,
    defaultState?: ITreeGridItemState,
  ) {
    if (!allowMultiple) empty(array, map)

    // Search for index of the first element that is bigger than the element we wish to insert
    let index = array.findIndex(el => el.index > itemIndex)
    index = index >= 0 ? index : array.length

    const itemState = defaultState
      ? defaultState
      : useTreeGridItemState({
          index: itemIndex,
          itemId: indexToRowId[itemIndex],
          treeState: state,
        })
    // Insert in a way that keeps the array sorted
    array.splice(index, 0, itemState)
    map[itemIndex] = itemState
  }

  /**
   * Removes indicated item from an array and its hash map of states
   * @param itemIndex Zero-based index of the item to be removed
   * @param array Array of states from which we will remove the state of the indicated item
   * @param map Hash map of states from which we will remove the state of the indicated item
   */
  function remove(itemIndex: number, array: ITreeGridItemState[], map: ITreeGridItemStateMap) {
    if (!is(itemIndex, map)) return

    array.splice(
      array.findIndex(x => x.index === itemIndex),
      1,
    )
    delete map[itemIndex]
  }

  /**
   * Adds indicated item to an array and its hash map of states if the item is not already there, otherwise removes it
   * @param itemIndex Zero-based index of the item whose state will be toggled
   * @param array Array of states from which we will add or remove the state of the indicated item
   * @param map Hash map of states from which we will add or remove the state of the indicated item
   * @param allowMultiple Boolean that indicates if multiple items are allowed to have the same state simultaneously
   */
  function toggle(itemIndex: number, array: ITreeGridItemState[], map: ITreeGridItemStateMap, allowMultiple = true) {
    if (!isNumeric(itemIndex)) return

    if (is(itemIndex, map)) remove(itemIndex, array, map)
    else add(itemIndex, array, map, allowMultiple)
  }

  /**
   * Replaces the array of states with a new array of states
   * @param newArray New array of states that will replace the previous one
   * @param array Array of states that will be emptied and filled with the new states
   * @param map Hash map of states that will be emptied and filled with the new states
   */
  function set(newArray: number[], array: ITreeGridItemState[], map: ITreeGridItemStateMap) {
    empty(array, map)
    newArray.forEach(n => add(n, array, map))
  }

  /**
   * Updates the state of the indicated item
   * @param itemIndex Zero-based index of the item whose state will be updated
   * @param array Array of states in which we will update the state of the indicated item
   * @param map Hash map of states in which we will update the state of the indicated item
   * @param newState New state object that will replace the previous one
   */
  function updateState(
    itemIndex: number,
    array: ITreeGridItemState[],
    map: ITreeGridItemStateMap,
    newState: ITreeGridItemState,
  ) {
    const index = array.findIndex(i => i.index === itemIndex)

    if (index < 0 || !is(itemIndex, map)) return

    array[index] = newState
    map[itemIndex] = newState
  }

  /**
   * Removes all items from the array of states and its associated hash map
   * @param array Array of states from which we will remove all states
   * @param map Hash map of states from which we will remove all states
   */
  function empty(array: ITreeGridItemState[], map: ITreeGridItemStateMap) {
    for (const key in map) {
      delete map[key]
    }
    array.length = 0
  }

  /**
   * Updates state array by replacing the current element with the next in order, if applicable, otherwise do nothing
   * @param array Array of states in which we will replace the current state with the next in order
   * @param map Hash map of states in which we will replace the current state with the next in order
   */
  function next(array: ITreeGridItemState[], map: ITreeGridItemStateMap) {
    const current = array.at(-1)?.index ?? -1

    if (state?.gridIndexLast && current < state.gridIndexLast) {
      add(current + 1, array, map, false)
    }
  }

  /**
   * Updates state array by replacing the current element with the previous in order, if applicable, otherwise do nothing
   * @param array Array of states in which we will replace the current state with the previous in order
   * @param map Hash map of states in which we will replace the current state with the previous in order
   */
  function previous(array: ITreeGridItemState[], map: ITreeGridItemStateMap) {
    const current = array.at(-1)?.index ?? -1

    if (current > 0) {
      add(current - 1, array, map, false)
    }
  }

  let gridCounter = 0
  let treeGridCounter = 0

  /**
   * Updates the index of the row in its active states (ex. selected)
   * This method keeps active states pointing to the right row after row indexes are recalculated in data updates.
   * @param rowId Unique identifier of the row
   * @param newIndex Newly calculated index of the row
   */
  function updateStateIndex(rowId: number, newIndex: number) {
    const highlightedRow = state.getHighlightedById(rowId)
    const selectedRow = state.getSelectedById(rowId)

    if (highlightedRow) highlightedRow.updateIndex(newIndex)
    if (selectedRow) selectedRow.updateIndex(newIndex)
  }

  /**
   * Calculates tree and grid row indexes and column headers, then updates both the state and the data tree given as argument.
   * @param input The data tree that will be updated with the indexes of its nodes & rows
   * @param level Zero-based index of current level in the recursive calling stack of the function
   */
  function calculateIndexesAndHeaders(input: ITreeGridNode, level = 0) {
    if (level === 0) {
      ;[gridCounter, treeGridCounter] = [0, 0]
      Object.keys(indexToRowId).forEach(key => delete indexToRowId[key as any])
      Object.keys(rowIdToIndex).forEach(key => delete rowIdToIndex[key as any])
    }

    ;(input.treeGridIndex = treeGridCounter++), (input.gridIndexFirst = gridCounter)

    input?.children?.forEach(i => calculateIndexesAndHeaders(i, level + 1)),
      input?.grid?.dataset?.rows?.forEach(row => {
        row.gridIndex = gridCounter++
        indexToRowId[row.gridIndex] = row.row_id
        rowIdToIndex[row.row_id] = row.gridIndex
        updateStateIndex(row.row_id, row.gridIndex)
        row.treeGridIndex = treeGridCounter++
      })

    input.gridIndexLast = gridCounter - 1
    input.treeGridIndexLast = treeGridCounter - 1

    if (level === 0) {
      state.gridIndexLast = gridCounter - 1
      state.treeGridIndexLast = treeGridCounter - 1
      state.columnHeaders = findColumnHeaders(input)
      state.minLevelWithGrid = findShallowestGrid(input)
    }
  }

  /**
   * Calculates the column headers of a tree
   * @param input The data tree for which we are searching its column headers
   */
  function findColumnHeaders(input: ITreeGridNode): IGridColumn[] {
    if (input?.grid?.dataset?.columns) return input.grid.dataset.columns

    for (let tree of input?.children ?? []) {
      const searchChildren = findColumnHeaders(tree)
      if (searchChildren) return searchChildren
    }

    return []
  }

  /**
   * Calculates the level of the most shallow grid, the first level to actually contain a grid with data
   * @param input The data tree for which we are searching its shallowest grid
   * @param level Zero-based index of current level in the recursive calling stack of the function
   */
  function findShallowestGrid(input: ITreeGridNode, level = 0): number {
    if (!!input?.grid?.dataset?.rows?.length) return level
    let minLevel = Infinity
    for (let child of input?.children ?? []) {
      const currentMin = findShallowestGrid(child, level + 1)
      if (currentMin < minLevel) minLevel = currentMin
    }
    return minLevel
  }

  let ticking = false
  let previousScrollTop = 0

  function updateScrollTopAnimation() {
    state.scrollTop = state?.overflowRef.value?.scrollTop ?? 0

    if (previousScrollTop === state.scrollTop) {
      ticking = false
      return
    }

    previousScrollTop = state.scrollTop
    requestAnimationFrame(updateScrollTopAnimation)
  }

  function updateScrollTop() {
    if (ticking) return

    ticking = true
    requestAnimationFrame(updateScrollTopAnimation)
  }

  function updateParents(
    activeRows: ITreeGridItemState<ITreeGridTableRow>[],
    collapsedNodes: ITreeGridItemState<ITreeGridNode>[],
  ) {
    let r = 0
    let c = 0

    while (r < activeRows.length) {
      const currentRow = activeRows[r]

      if (c >= collapsedNodes.length) {
        r++
        currentRow.removeCollapsedParent()
        continue
      }

      const currentNode = collapsedNodes[c]

      const min = currentNode.itemData?.gridIndexFirst
      const max = currentNode.itemData?.gridIndexLast

      if ((!min && min !== 0) || !max) {
        c++
        continue
      }

      if (currentRow.index < min) {
        r++
        currentRow.removeCollapsedParent()
      } else if (currentRow.index > max) {
        c++
      } else {
        r++
        currentRow.setCollapsedParent(currentNode)
      }
    }
  }

  watchEffect(() => {
    updateParents(state.selected, state.collapsed)
    updateParents(state.highlighted, state.collapsed)
  })

  return readonly(state)
}

const treeGridKeys = {
  /**
   * State object that allows different parts of the tree to coordinate
   */
  state: Symbol() as InjectionKey<ITreeGridState>,
}

export { treeGridKeys }
