<template>
  <!-- tree-grid-header-columns -->
  <div class="flex h-9 items-center text-black">
    <tree-sticky-row
      class="flex items-stretch"
      :is-sticky="state.isSticky"
      :left-offset-total="props.leftOffset"
      :sticky-side-width="0"
    >
      <template #aside>
        <div
          v-for="(column, index) in headers.slice(0, state.freezedColumns)"
          class="truncate bg-white pl-2 text-sm font-medium"
          :style="{ minWidth: (columnSizes?.[index] ?? 100) + columnSizeMeasurement }"
        >
          {{ column.label }}
        </div>
      </template>

      <div
        v-for="(column, index) in headers"
        class="truncate pl-2 text-sm font-medium"
        :style="{ minWidth: (columnSizes?.[index] ?? 100) + columnSizeMeasurement }"
      >
        {{ column.label }}
      </div>
    </tree-sticky-row>
  </div>
</template>

<script setup lang="ts">
import { ITreeGridNode as ITreeGridNodeHack, ITreeGridState as ITreeGridStateHack } from '@common'
import { computed } from 'vue'
interface ITreeGridNode extends ITreeGridNodeHack {}
interface ITreeGridState extends ITreeGridStateHack {}

interface IProps {
  /**
   * State object that allows different parts of the tree to coordinate
   */
  state: ITreeGridState
  /**
   * Data representing current grid
   */
  data: ITreeGridNode
  /**
   * Offset (in px) that is applied to the left of each node
   */
  leftOffset: number
}

const props = withDefaults(defineProps<IProps>(), {})

const headers = computed(() =>
  props.state.showIndex
    ? [{ label: '#' }].concat((props?.state?.columnHeaders ?? []) as any[])
    : props?.state?.columnHeaders ?? [],
)
const columnSizes = computed(() =>
  props.state.showIndex ? [props.state.minIndexColumnSize].concat(props.state.columnSizes) : props.state.columnSizes,
)
const firstColumnIndex = computed(() => (props.state.showIndex ? 1 : 0))
const columnSizeMeasurement = computed(() => (props.state.isPercent ? '%' : 'px'))
</script>
