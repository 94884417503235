<script lang="ts" setup>
import { ref, onMounted } from "vue"
import { useRoute } from "vue-router"
import Operations from "../data/operations.json"

// const route = useRoute()

const allPositions = Operations.map((o) => o.POSITION)

const position = ref(allPositions[0])

onMounted(() => {
  const pos = new URLSearchParams(location.search).get("pos")
  if (pos) position.value = pos
})
</script>
<script lang="ts">
export const additions = { icon: "pt-icon-table" }
</script>

<template>
  <h1 class="text-3xl font-bold">{{ t.operations }}</h1>

  <div style="position: fixed; top: 1px; z-index: 99999" class="top-control whitespace-nowrap rounded-lg border border-white px-4 py-0 text-white">
    <div class="flex">
      <div class="col flex items-center">
        <div class="!text-[#cecece]">{{ t.position }} :</div>
        <select v-model="position" class="px-4">
          <option v-for="p in allPositions" :key="p" :value="p">{{ p }}</option>
        </select>
      </div>
    </div>
  </div>
</template>

<style scoped>
.top-control {
  background-color: var(--nav-retract-button-background) !important;
}

select {
  background-color: var(--nav-retract-button-background) !important;
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" stroke="%23fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" fill="none" shape-rendering="geometricPrecision"><path d="M6 9l6 6 6-6"/></svg>') !important;
  background-position: right center !important;
}
</style>
