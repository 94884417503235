import { reactive, Ref, unref } from 'vue'

/**
 * Object that allows to calculate the bounding box of an element
 */
export interface IUsePosition {
  /**
   * Calculates the top bound of the element
   */
  getTop(): number | undefined
  /**
   * Calculates the right bound of the element given its width
   */
  getRight(): number | undefined
  /**
   * Calculates the bottom bound of the element given its height
   */
  getBottom(): number | undefined
  /**
   * Calculates the left bound of the element
   */
  getLeft(): number | undefined
  /**
   * Calculates the middle of the element on the Y axis
   */
  getMiddleY(): number | undefined
  /**
   * Calculates the middle of the element on the X axis
   */
  getMiddleX(): number | undefined
}

export interface IOffsetBox {
  top?: Ref<number | undefined> | number
  left?: Ref<number | undefined> | number
}

/**
 * Calculate the position of the element relative to a reference element and an offset box
 * @param el The element whose position we are calculating
 * @param refEl A parent element relative to which we wish to calculate the position
 * @param offsetBox An offset box relative to which we wish to calculate the position
 */
export function useRelativePosition(
  el: Ref<HTMLElement | undefined>,
  refEl?: Ref<HTMLElement | undefined> | null,
  offsetBox?: IOffsetBox,
): IUsePosition | undefined {
  const relativePosition = refEl ? usePosition(refEl) : null

  const store = reactive<IUsePosition>({
    getTop,
    getRight,
    getBottom,
    getLeft,
    getMiddleY,
    getMiddleX,
  })

  function getTop() {
    if (!el || !el.value) return
    return el.value.getBoundingClientRect().y - (relativePosition?.getTop() ?? 0) - (unref(offsetBox?.top) ?? 0)
  }

  function getRight() {
    if (!el || !el.value) return
    return (getLeft() ?? 0) + el.value.clientWidth
  }

  function getBottom() {
    if (!el || !el.value) return
    return (getTop() ?? 0) + el.value.clientHeight
  }

  function getLeft() {
    if (!el || !el.value) return
    return el.value.getBoundingClientRect().x - (relativePosition?.getLeft() ?? 0) - (unref(offsetBox?.left) ?? 0)
  }

  function getMiddleX() {
    if (!el || !el.value) return
    return (getLeft() ?? 0) + el.value.clientWidth / 2
  }

  function getMiddleY() {
    if (!el || !el.value) return
    return (getTop() ?? 0) + el.value.clientHeight / 2
  }

  return store
}

/**
 * Calculate the position of the element relative to an offset box
 * @param el The element whose position we are calculating
 * @param offsetBox An offset box relative to which we wish to calculate the position
 */
export function usePosition(el: Ref<HTMLElement | undefined>, offsetBox?: IOffsetBox) {
  return useRelativePosition(el, null, offsetBox)
}
