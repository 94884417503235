<script>
export const additions = { icon: "ic_pie_chart", calendar: "daily" }
</script>
<script setup>
import { ref, computed } from "vue"
import Operations from "../data/operations.json"
$root.lang = "fr"
$root.dates = [new Date().format()]
const formatNumber = (v) =>
  typeof v === "number" ? new Intl.NumberFormat("fr", { maximumFractionDigits: 2 }).format(v) : v
const data = Operations
const options = {
  primary_columns: [
    "Investissement",
    "Engagé",
    "Investi",
    "Engagement Résiduel",
    "Reçu",
    "Valeur latente",
    "Valeur totale",
    "Plus Value",
    "Multiple",
    "TRI",
  ],
  sort_column: "Investissement",
  table_size: 10,
  format: {
    Engagé: formatNumber,
    Investi: formatNumber,
    "Engagement Résiduel": formatNumber,
    Reçu: formatNumber,
    "Valeur latente": formatNumber,
    "Valeur totale": formatNumber,
    "Plus Value": formatNumber,
    Multiple: formatNumber,
  },
}

const currentFilters = ref($root.filters.__.filter((v, k) => v && v.filter().length && k !== "domain"))

// prettier-ignore
const xdata = computed(() => {
  debugger
  update_query(currentFilters.value.__.filter((v) => v).__.map(v => v.length ? v : '') || {})
  const filters = Object.entries(currentFilters.value).filter(([k, v]) => v.length)
  const xfilter = (levels) => sort(data.filter(row => filters.every(([k, v]) => levels.includes(k) || v.includes(row[k]))).group(levels).__.map((lvl1) => lvl1.__.map((lvl2) => lvl2.map("Investi").sum())))
  const sort = obj => Object.fromEntries(Object.entries(obj).sort(([k, v]) => -Object.values(v).sum()).map(([k, v]) => [k, Object.fromEntries(Object.entries(v).sort(([k, v]) => -v))]))
  const table = data.filter(row => filters.every(([k, v]) => v.includes(row[k]))).group('Investissement').__.v().map(grp => {
    const v = grp.reduce((acc, line) => { 
      Object.entries(line).map(([k, v]) => typeof v === 'string' ? acc[k] = v : acc[k] = (acc[k] || 0) + v)
      return acc
    }, {})
    v["Valeur totale"] = v["Valeur latente"] + v["Reçu"]
    v["Plus Value"] = v["Valeur totale"] - v["Investi"] || 0
    v["Multiple"] = v["Valeur totale"] / v["Investi"] || 0
    v["TRI"] = "-"
    return v
  })
  const total = Object.fromEntries(options.primary_columns.map(k => [k, typeof table[0]?.[k] === 'string' ? table[0]?.[k] : table.map(k).sum()]))
  return {
    table,
    total,
    one: xfilter(["Investissement", "Programme"]),
    two: xfilter(["Stratégie", "Produit"]),
    three: xfilter(["Devise", null]),
    four: xfilter(["Zone", null]),
  }
})
const clickLine = ($event) => {
  if (!$event.path.find((el) => el.tagName === "TBODY")) return
  if (!currentFilters.value) {
    debugger
  }
  if (currentFilters.value?.Investissement?.length) return (currentFilters.value.Investissement = [])
  return (currentFilters.value.Investissement = [$event.path.find((el) => el.tagName === "TR").children[0].innerText])
}
</script>

<template>
  <div class="row">
    <h1 class="text-3xl font-bold">{{ t.slash }}</h1>
    <div class="grow"></div>
    <div v-for="(item, name) in currentFilters">
      <template v-if="item.length">
        <span
          class="inline-flex cursor-pointer items-center rounded-md bg-indigo-100 px-2.5 py-0.5 text-lg text-indigo-800"
          @click="currentFilters[name] = []"
        >
          <span>{{ name }}:</span>
          <span class="ml-1 font-medium">{{ item[0] }}</span>
          <svg class="m-2 h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
            <path stroke-linecap="round" stroke-width="1.5" d="M1 1l6 6m0-6L1 7" />
          </svg>
        </span>
      </template>
    </div>
  </div>
  <div class="row flex-nowrap overflow-auto">
    <block title="Investissement/Programme">
      <sunbirst
        :data="xdata.one"
        :metadata="{
          format: '.3s€',
          selection: currentFilters.Investissement?.[0],
          selection2: currentFilters.Programme?.[0],
        }"
        @plotclick="currentFilters.Investissement = [$event]"
        @plotclick_sub="currentFilters.Programme = [$event]"
        @plotclick_center="currentFilters.Investissement = currentFilters.Programme = []"
      ></sunbirst>
    </block>
    <block title="Stratégie/Produit">
      <sunbirst
        :data="xdata.two"
        :metadata="{
          format: '.3s€',
          selection: currentFilters.Stratégie?.[0],
          selection2: currentFilters.Produit?.[0],
        }"
        @plotclick="currentFilters.Stratégie = [$event]"
        @plotclick_sub="currentFilters.Produit = [$event]"
        @plotclick_center="currentFilters.Stratégie = currentFilters.Produit = []"
      ></sunbirst>
    </block>
    <block title="Devise">
      <sunbirst
        :data="xdata.three"
        :metadata="{ format: '.3s€', selection: currentFilters.Devise?.[0], selection2: null }"
        @plotclick="currentFilters.Devise = [$event]"
        @plotclick_center="currentFilters.Devise = []"
      ></sunbirst>
    </block>
    <block title="Zone">
      <sunbirst
        :data="xdata.four"
        :metadata="{ format: '.3s€', selection: currentFilters.Zone?.[0], selection2: null }"
        @plotclick="currentFilters.Zone = [$event]"
        @plotclick_center="currentFilters.Zone = []"
      ></sunbirst>
    </block>
  </div>
  <new-table :data="xdata.table" :options="options" @click="clickLine">
    <template #tfoot>
      <tfoot class="border-t-2">
        <tr>
          <td v-for="(column, index) in options.primary_columns" class="min-w-[140px] whitespace-nowrap px-4 py-2">
            <div class="font-bold">{{ index === 0 ? "TOTAL" : formatNumber(xdata.total[column]) }}</div>
          </td>
        </tr>
      </tfoot>
    </template>
  </new-table>
</template>

<style>
* {
  font-variant: tabular-nums;
}
tr:nth-child(n + 1) > td:nth-child(n + 2) {
  text-align: right;
}
</style>
