import { pagesFromGlobRecord, componentsFromGlobRecord } from "@100-m/hauru/src/utils/platformBuilder"
import config from "./index.yml"
const translations = config.translation

export default {
  /**
   * App name
   */
  // name: "agache-poc",
  /**
   * Listed
   */
  // listed: true,
  /**
   * If common, this app will be merged with others
   */
  common: false,
  /**
   * Prefix to apply on the pages routes
   */
  // routePrefix: "/agache-poc",
  /**
   * Pages components to map to the router
   */
  pages: pagesFromGlobRecord(import.meta.globEager("./pages/**/*.vue"), "./pages"),
  /**
   * Components to add to the app
   */
  components: componentsFromGlobRecord(import.meta.globEager("./components/**/*.vue")),
  /**
   * Translations to add
   */
  translations,
  /**
   * Config partial
   */
  config,
}
