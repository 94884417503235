<template>
  <!-- tree-grid -->
  <!-- Setting text color sets the background color of the tree -->
  <div :class="[props.level === 0 ? 'relative flex h-full flex-col' : '']" ref="treeRootRef">
    <div v-if="props.level === 0">
      <!--
        @slot Header with actions that appear above the tree 
        @binding {IProps} tree-grid-props All props available to the tree grid
      -->
      <slot name="tree-header-actions" :tree-grid-props="props"></slot>
    </div>
    <div
      :class="[
        props.level === 0 ? 'grid w-full-scrollbar grow content-start overflow-auto overscroll-auto rounded-md' : '',
      ]"
      @[scrollOnTrackPositions].passive="emit('overflow-scroll', $event)"
      ref="treeOverflowRef"
    >
      <!-- White background of header columns -->
      <div v-if="props.level === 0" class="sticky top-0 z-[130] bg-white" ref="treeHeaderRef">
        <tree-grid-header-columns
          :data="props.data"
          :state="props.state"
          :left-offset="leftOffset"
        ></tree-grid-header-columns>
      </div>
      <slot name="above-node" :tree-grid-props="props"></slot>
      <tree-node
        :tree-styles="evaluatedTreeStyles"
        :is-sticky="state.isSticky"
        :left-offset="leftOffset"
        :left-offset-total="props.leftOffsetTotal"
        @toggle-parents="emit('toggle-parents', $event)"
        @expand="onCurrentNodeExpanded"
        @collapse="onCurrentNodeCollapsed"
        ref="nodeRef"
      >
        <template #content="{ isExpanded, leftOffsetTotal, toggleExpanded, toggleParents }">
          <draggable
            class="w-full"
            :class="[isExpanded ? '' : 'h-0 overflow-y-hidden']"
            tag="div"
            :list="props.data.children"
            :group="{ name: props.data.drag_group }"
            handle=".handle"
            item-key="component_id"
          >
            <template #item="{ element, index }: { element: ITreeGridNode, index: number }">
              <tree-grid
                :state="props.state"
                :level="props.level + 1"
                :data="element"
                :tree-styles="props.treeStyles"
                :left-offset-total="leftOffsetTotal"
                @toggle-parents="toggleParents"
                @expand="emit('expand', $event)"
                @collapse="emit('collapse', $event)"
              >
                <template #node-header="{ treeGridProps, isExpanded, toggleExpanded }">
                  <slot
                    name="node-header"
                    :tree-grid-props="treeGridProps"
                    :is-expanded="isExpanded"
                    :toggle-expanded="toggleExpanded"
                  ></slot>
                </template>
                <template #node-footer="{ treeGridProps, isExpanded, toggleExpanded }">
                  <slot
                    name="node-footer"
                    :tree-grid-props="treeGridProps"
                    :is-expanded="isExpanded"
                    :toggle-expanded="toggleExpanded"
                  ></slot>
                </template>
                <template #above-node="{ treeGridProps }">
                  <slot name="above-node" :tree-grid-props="treeGridProps"></slot>
                </template>
                <template #below-node="{ treeGridProps }">
                  <slot name="below-node" :tree-grid-props="treeGridProps"></slot>
                </template>
                <template #spreadsheet="{ treeGridProps, leftOffsetTotal, toggleExpanded, toggleParents }">
                  <slot
                    name="spreadsheet"
                    :tree-grid-props="treeGridProps"
                    :left-offset-total="leftOffsetTotal"
                    :toggle-expanded="toggleExpanded"
                    :toggle-parents="toggleParents"
                  ></slot>
                </template>
              </tree-grid>
            </template>
            <template #footer>
              <div class="flex w-full" v-if="props.data.grid?.dataset">
                <!--
                  @slot Spreasheet slot necessary to define the appearence of grids inside the tree
                  @binding {IProps}     tree-grid-props     All props available to the tree grid
                  @binding {number}     left-offset-total   Total offset from the left (in px) of the grid based on the nodes current level on the tree
                  @binding {boolean}    is-expanded         Boolean that indicates if current node is expanded or not
                  @binding {function}   toggle-expanded     Function that allows to toggle the expanded state of the tree node
                  @binding {function}   toggle-parents      Function that allows to toggle the expanded state of the parent nodes
                -->
                <slot
                  name="spreadsheet"
                  :tree-grid-props="props"
                  :left-offset-total="leftOffsetTotal"
                  :is-expanded="isExpanded"
                  :toggle-expanded="toggleExpanded"
                  :toggle-parents="toggleParents"
                ></slot>
              </div>
            </template>
          </draggable>
        </template>
        <template #header="{ isExpanded, toggleExpanded, toggleParents }">
          <!--
            @slot Header of a tree node
            @binding {IProps}     tree-grid-props   All props available to the tree grid
            @binding {boolean}    is-expanded       Boolean that indicates if current node is expanded or not
            @binding {function}   toggle-expanded   Function that allows to toggle the expanded state of the tree node
            @binding {function}   toggle-parents    Function that allows to toggle the expanded state of the parent nodes
          -->
          <slot
            name="node-header"
            :tree-grid-props="props"
            :is-expanded="isExpanded"
            :toggle-expanded="toggleExpanded"
            :toggle-parents="toggleParents"
          ></slot>
        </template>
        <template #footer="{ isExpanded, toggleExpanded, toggleParents }">
          <!--
            @slot Footer of a tree node
            @binding {IProps}     tree-grid-props   All props available to the tree grid
            @binding {boolean}    is-expanded       Boolean that indicates if current node is expanded or not
            @binding {function}   toggle-expanded   Function that allows to toggle the expanded state of the tree node
            @binding {function}   toggle-parents    Function that allows to toggle the expanded state of the parent nodes
          -->
          <slot
            name="node-footer"
            :tree-grid-props="props"
            :is-expanded="isExpanded"
            :toggle-expanded="toggleExpanded"
            :toggle-parents="toggleParents"
          ></slot>
        </template>
      </tree-node>
      <slot name="below-node" :tree-grid-props="props"></slot>
    </div>
    <!-- Hack to fix error -->
    <slot v-if="false" name="above-node" :tree-grid-props="props"></slot>
  </div>
</template>

<script setup lang="ts">
import {
  ITreeGridNode as ITreeGridNodeHack,
  ITreeGridState as ITreeGridStateHack,
  ITreeStyles as ITreeStylesHack,
  evaluateStyles,
  treeGridKeys,
  useTreeGridItemState,
  ElementReactiveRef,
} from '@common'
import { useElementSize } from '@vueuse/core'
import { computed, onMounted, provide, ref, watchEffect } from 'vue'
import draggable from 'vuedraggable'

// Temporarily necessary in recursive components because of bug related to our current Vue setup, unknown cause
interface ITreeGridNode<T = any> extends ITreeGridNodeHack<T> {}
interface ITreeGridState extends ITreeGridStateHack {}
interface ITreeStyles<T> extends ITreeStylesHack<T> {}

type ITreeStylesSpecific = ITreeStyles<Required<Omit<IProps, 'treeStyles'>>>

interface IProps {
  /**
   * State object that allows different parts of the tree to coordinate
   */
  state: ITreeGridState
  /**
   * Data representing current tree
   */
  data: ITreeGridNode
  /**
   * Total offset from the left (in px) based on the nodes current level on the tree
   * @private
   */
  leftOffsetTotal?: number
  /**
   * Zero-based level of current node inside tree
   * @private
   */
  level?: number
  /**
   * Boolean indicating whether the component should expose a scrolling event
   */
  trackPositions?: boolean
  /**
   * Styles applied to tree nodes
   */
  treeStyles?: ITreeStylesSpecific
}

const props = withDefaults(defineProps<IProps>(), {
  level: 0,
  leftOffsetTotal: 0,
  trackPositions: false,
})

provide(treeGridKeys.state, props.state)

const emit = defineEmits<{
  /**
   * Directs parent nodes to expand, collapse or toggle their state
   * @param shouldExpand Boolean that indicates whether parent nodes should expand (true), collapse (false) or toggle (undefined)
   * @private
   */
  (e: 'toggle-parents', shouldExpand?: boolean): void
  /**
   * Fires when a node expanded
   * @param nodeData Data representing tree node that expanded
   */
  (e: 'expand', nodeData?: ITreeGridNode): void
  /**
   * Fires when a node collapsed
   * @param nodeData Data representing tree node that collapsed
   */
  (e: 'collapse', nodeData?: ITreeGridNode): void
  /**
   * Fires when we scroll inside the overflow container of the component
   * @param event Scroll event
   */
  (e: 'overflow-scroll', event?: Event): void
}>()

const treeRootRef = ref<HTMLElement>()
const treeOverflowRef = ref<HTMLElement>()
const treeHeaderRef = ref<HTMLElement>()
const nodeRef = ref<{ nodeRef: ElementReactiveRef | undefined }>()

const nodeState = useTreeGridItemState({
  index: props.data.treeGridIndex ?? 0,
  itemId: props.data.component_id,
  itemData: props.data,
  treeState: props.state,
})

const scrollOnTrackPositions = computed<any>(() => (props.trackPositions ? 'scroll' : null))
const evaluatedTreeStyles = computed(() => {
  const evalStyles = evaluateStyles(props.treeStyles, props) as ITreeStylesSpecific
  if (props.level === 0 && props.state.isPercent) evalStyles.class_node_root = ['min-w-0', evalStyles.class_node_root]
  return evalStyles
})
const leftOffset = computed(() => (evaluatedTreeStyles.value?.left_offset as number) ?? 32)

const treeHeaderRefSize = useElementSize(treeHeaderRef)

watchEffect(() => {
  nodeState.updateIndex(props.data.treeGridIndex ?? 0)
})

watchEffect(() => {
  if (nodeRef.value?.nodeRef) nodeState.itemRef.replaceRef(nodeRef.value?.nodeRef?.getRef())
})

if (props.level === 0) {
  props.state.rootRef.replaceRef(treeRootRef)
  props.state.overflowRef.replaceRef(treeOverflowRef)
  props.state.headerRef.replaceRef(treeHeaderRef)
}

onMounted(() => {
  watchEffect(() => {
    if (props.level === 0) props.state.calculateIndexesAndHeaders(props.data)
  })
})

function onCurrentNodeExpanded() {
  props.state.removeCollapsed(props.data.treeGridIndex!)
  emit('expand', props.data)
}

function onCurrentNodeCollapsed() {
  props.state.addCollapsed(props.data.treeGridIndex!, nodeState)
  emit('collapse', props.data)
}
</script>
