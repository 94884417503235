import { IClassStringArray, IGenericStyles } from '@common'
import { CSSProperties } from 'vue'

/**
 * Executes any functions contained in the style object passing the given props as argument
 * @param styles Style object that is being evaluated
 * @param props The props of the component whose styles we are evaluating
 */
export function evaluateClassStringArray(
  styles: IClassStringArray | number | CSSProperties,
  props: any,
): typeof styles {
  if (!styles && styles !== 0) return

  if (Array.isArray(styles)) return styles.map(i => evaluateClassStringArray(i, props)) as IClassStringArray
  else if (styles instanceof Function) return styles(props)
  else if (typeof styles === 'object') return evaluateStyles(styles, props) as CSSProperties

  return styles
}

/**
 * Executes any functions contained in the style object passing the given props as argument
 * @param styles Style object that is being evaluated
 * @param props The props of the component whose styles we are evaluating
 * @param args Any other arguments we wish to add that will cause the reevaluation of styles
 */
export function evaluateStyles(
  styles: IGenericStyles | CSSProperties | undefined,
  props: any,
  ...args: any[]
): IGenericStyles {
  if (!styles) return {}

  const obj: IGenericStyles = {}

  for (let styleKey in styles) {
    //@ts-ignore
    obj[styleKey] = evaluateClassStringArray(styles[styleKey], props)
  }
  return obj
}
