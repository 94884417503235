<template>
  <details ref="dropdownRef">
    <summary>
      <slot name="title"></slot>
    </summary>
    <div
      class="absolute z-[200] mt-1 h-80 w-56 divide-y divide-gray-100 overflow-auto rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none"
    >
      <slot></slot>
    </div>
  </details>
</template>

<script setup lang="ts">
import { onClickOutside } from '@vueuse/core'
import { ref } from 'vue'

const dropdownRef = ref<HTMLElement>()

onClickOutside(dropdownRef, event => dropdownRef.value?.removeAttribute('open'))
</script>
